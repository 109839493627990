import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './login-facilitator.scss';

const LoginFacilitator = ({cookiesAccepted, toggleCookiePopup, facilitatorLogin, setFacilitatorAction}) => {
	/* Loading state */
	const [isLoading, setIsLoading] = useState(false);

	/* E-mail */
	const [email, setEmail] = useState(null);

	/* Password */
	const [password, setPassword] = useState(null);

	/* Err msg */
	const [errMsg, setErrMsg] = useState(null);

	/**
	 * Handle email input
	 * @param {object} event 
	 */
	const handleEmailInput = (event) => {
		setEmail(event.target.value);
	};

	/**
	 * Handle password input
	 * @param {object} event 
	 */
	const handlePasswordInput = (event) => {
		setPassword(event.target.value);
	};

	/**
	 * Login with email and password
	 */
	const handleFacilitatorLogin = (event) => {
		if (event) event.preventDefault();

		if (!cookiesAccepted) {
			toggleCookiePopup(true);
		} else if (!email || email.length === 0 || !password || password.length === 0) {
			setErrMsg(getText('errorTexts', 'missingFields'));
		} else {
			setIsLoading(true);
			facilitatorLogin(email, password).catch((error) => {
				let newErrMsg = getText('errorTexts', 'unknownError');
				if (error.code === 'auth/invalid-email') newErrMsg = getText('errorTexts', 'invalidEmail');
				if (error.code === 'auth/user-not-found') newErrMsg = getText('errorTexts', 'emailNotFound');
				if (error.code === 'auth/wrong-password') newErrMsg = getText('errorTexts', 'invalidEmailPassword');
				setErrMsg(newErrMsg);
				setIsLoading(false);
			});
		}
	};

	return (
		<div className='LoginFacilitator'>
			<form className='LoginFacilitator-loginForm' onSubmit={handleFacilitatorLogin}>
				{/* Email */}
				<div className='LoginFacilitator-label'>
					<span>{getText('generalTexts', 'email')}</span>
				</div>
				<input
					className='LoginFacilitator-input'
					name="email"
					type="text"
					autoComplete="section-facilitatorlogin email"
					value={email ? email : ''} 
					onChange={(event)=>{handleEmailInput(event);}}
				/>
				{/* Password */}
				<div className='LoginFacilitator-label'>
					<span>{getText('generalTexts', 'password')}</span>
				</div>
				<input
					className='LoginFacilitator-input'
					name="password"
					type="password"
					autoComplete="section-facilitatorlogin password"
					value={password ? password : ''} 
					onChange={(event)=>{handlePasswordInput(event);}}
				/>
					
				{/* Reset btn */}
				<div 
					className='LoginFacilitator-resetPassword'
					onClick={() => {setFacilitatorAction('reset-password');}}
				>
					<span>{getText('generalTexts', 'forgottenPassword')}</span>
				</div>

				<div className='LoginFacilitator-buttons'>
					{/* Create user btn */}
					<div className='LoginFacilitator-button'>
						<Button
							classes={['pink', 'fullHeight']}
							text={getText('generalTexts', 'createUser')}
							onClick={() => {setFacilitatorAction('create-user');}}
						/>
					</div>
					{/* Login btn */}
					<div className='LoginFacilitator-button'>
						<Button
							type="submit"
							isLoading={isLoading}
							classes={['pink', 'fullHeight']}
							text={getText('generalTexts', 'login')}
						/>
					</div>
				</div>
				<div className='LoginFacilitator-errMsg'><span>{errMsg}</span></div>
			</form>
		</div>
	);
};

LoginFacilitator.propTypes = {
	cookiesAccepted: PropTypes.bool.isRequired,
	toggleCookiePopup: PropTypes.func.isRequired,
	facilitatorLogin: PropTypes.func.isRequired,
	setFacilitatorAction: PropTypes.func.isRequired
};

export default LoginFacilitator;