import {useState, useEffect} from 'react';
import firebaseInit from 'firebase-init';
import {getOrientation, getAspectRatio} from 'helpers/device-helper';
import LandingPage from './landing-page/landing-page';
import LoginController from './login/login-controller';
import Background from './background/background';
import './app.scss';

const App = () => {
	const [showLandingPage, setShowLandingPage] = useState(true);

	/* Device info */
	const [deviceInfo, setDeviceInfo] = useState(() => {
		return {
			orientation: getOrientation(),
			aspectRatio: getAspectRatio()
		};
	});

	/**
	 * User resized window, update orientation and aspect ratio
	 */
	const handleWindowResize = () => {
		const newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.orientation = getOrientation();
		newDeviceInfo.aspectRatio = getAspectRatio();
		setDeviceInfo(newDeviceInfo);
	};
 
	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */ 

		/* Initialize firebase */
		firebaseInit();

		/* Add event listener for window size */
		if (document.addEventListener) window.addEventListener('resize', handleWindowResize, false);

		/* Component will ummount */
		return () => {
			if (document.removeEventListener) window.removeEventListener('resize', handleWindowResize, false);
		};
	});

	return (
		<div className="App">
			<Background deviceInfo={deviceInfo}/>
			{showLandingPage ? 
				<LandingPage 
					deviceInfo={deviceInfo} 
					setShowLandingPage={setShowLandingPage}
				/>
				: 
				<LoginController 
					deviceInfo={deviceInfo} 
				/>
			}
		</div>
	);
};

export default App;
