/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyCp0Kvbt1d0zKKmcxTk7az3k7_ODPQNl4k',
		authDomain: 'cgl-foraeldremodespil-test.firebaseapp.com',
		projectId: 'cgl-foraeldremodespil-test',
		storageBucket: 'cgl-foraeldremodespil-test.appspot.com',
		messagingSenderId: '1031134938505',
		appId: '1:1031134938505:web:46b23a41d37190d3a3cd58'
	};
}

/* Connect to firebase project production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyCHLIZn2eVxiUJTV5J2-LP87tIWvehfTzE',
		authDomain: 'cgl-foraeldremodespil-pro.firebaseapp.com',
		projectId: 'cgl-foraeldremodespil-pro',
		storageBucket: 'cgl-foraeldremodespil-pro.appspot.com',
		messagingSenderId: '634917136272',
		appId: '1:634917136272:web:654eb7b8ec1ba25ef9f515'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;