import React from 'react';
import PropTypes from 'prop-types';
import {getText, renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './popup-group-warning.scss';

const PopupGroupWarning = ({deviceInfo, goToNextPage, setShowWarningPopup}) => {
 
	return (
		<div className={'PopupGroupWarning ' + deviceInfo.orientation}>
			<div className="PopupGroupWarning-content">
				<div className="PopupGroupWarning-closeBtn" onClick={() => {setShowWarningPopup(false);}}/>
				<div className="PopupGroupWarning-text">
					{renderMarkdown(getText('generalTexts', 'groupWarningText'))}
				</div>
				
				<div className="PopupGroupWarning-buttons">
					<div className="PopupGroupWarning-button yes">
						<Button 
							text={getText('generalTexts', 'yesContinue')} 
							classes={['pink']} 
							onClick={() => {goToNextPage();}} 
						/>
					</div>
					<div className="PopupGroupWarning-button no">
						<Button 
							text={getText('generalTexts', 'noCancel')} 
							classes={['pink']} 
							onClick={() => {setShowWarningPopup(false);}} 
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PopupGroupWarning.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	goToNextPage: PropTypes.func.isRequired,
	setShowWarningPopup: PropTypes.func.isRequired,
};

export default PopupGroupWarning;
