import React from 'react';
import PropTypes from 'prop-types';
import './background.scss';

const Background = ({deviceInfo}) => {

	return (
		<div className={'Background ' + deviceInfo.orientation} />
	);
};

Background.propTypes = {
	deviceInfo: PropTypes.object.isRequired
};

export default Background;