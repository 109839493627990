import React from 'react';
import PropTypes from 'prop-types';
import Result from '../results/result';
import './popup-result.scss';

const PopupResult = ({deviceInfo, resultId, gameData, groupsData, toggleResultPopup}) => {

	return (
		<div className={'PopupResult ' + deviceInfo.orientation} onClick={() => {toggleResultPopup(null);}}>
			<div className={'PopupResult-content'} onClick={(e) => {e.stopPropagation();}}>
				{/* Result */}
				<Result resultId={resultId} gameData={gameData} groupsData={groupsData} />

				{/* Close btn */}
				<div className="PopupResult-closeBtn" onClick={() => {toggleResultPopup(null);}}/>
			</div>
		</div>	
	);
};

PopupResult.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	resultId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	groupsData: PropTypes.array.isRequired,
	toggleResultPopup: PropTypes.func.isRequired
};

export default PopupResult;
