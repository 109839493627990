import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import CompanyLogos from 'components/ui/company-logos/company-logos';
import './landing-page.scss';

const LandingPage = ({deviceInfo, setShowLandingPage}) => {

	return (
		<div className={'LandingPage ' + deviceInfo.orientation}>
			{/* Logo */}
			<div className="LandingPage-logo">
				<Logo page={deviceInfo.orientation + '-login'} />
			</div>
			
			{/* Game title */}
			<div className='LandingPage-title'>
				<span>{getText('generalTexts', 'gameTitleSplit')}</span>
			</div>

			{/* Start btn */}
			<div className={'LandingPage-startBtn'}>
				<Button 
					classes={['pink', 'fullHeight']}
					text={getText('generalTexts', 'start')}
					onClick={() => {setShowLandingPage(false);}}
				/>
			</div>

			<CompanyLogos deviceInfo={deviceInfo} />

		</div>
	);
};

LandingPage.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	setShowLandingPage: PropTypes.func.isRequired
};

export default LandingPage;