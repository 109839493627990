import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import './pie-chart.scss';

const PieChart = ({type, values, labels = []}) => {
	const colors = ['#2B2B6B', '#1B65CE', '#CBB6F1', '#F9413F', '#FDA4BD'];

	Chart.register(CategoryScale);
	const chartData = {labels: labels, datasets: [{data: values, backgroundColor: colors}]};
	const options = {
		plugins: {
			tooltip: {
				callbacks: {
					label: function(tooltipItem) {
						return tooltipItem.formattedValue + '%';
					}
				}
			},
		},
	};

	return (
		<div className={'PieChart' + (type ? ' ' + type : '')}>
			<Pie data={chartData} options={options} />
		</div>
	);
};

PieChart.propTypes = {
	type: PropTypes.string,
	values: PropTypes.array.isRequired,
	labels: PropTypes.array
};

export default PieChart;