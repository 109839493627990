import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {getCookie, setCookie, deleteCookie} from 'helpers/cookie-helper';
import PopupConfirmCookies from 'components/ui/popup-confirm-cookies/popup-confirm-cookies';
import FacilitatorController from 'components/users/facilitator-controller';
import GroupController from 'components/users/group-controller';
import Login from './login';
import Button from 'components/ui/button/button';
import './login.scss';

class LoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isLoggedIn: false,
			cookiesAccepted: false,
			showCookiePopup: false,
			userId: null,
			isFacilitator: false,
			isCreatingUser: false,
			isResettingPassword: false,
		};
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Check cookie consent */
		const cookieConsentData = getCookie(appConfig.cookieConsentCookieName);
		if (cookieConsentData && cookieConsentData.length > 0) {
			this.setState({cookiesAccepted: true});
		} else {
			this.setState({showCookiePopup: true});
		}

		/* Check if logged in */
		this.checkIfLoggedIn();
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Check if user is logged in
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				/* Ok to cookies */
				const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
				if (!cookieConsent) {
					setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
				}

				const isFacilitator = user.email !== null;
				if (isFacilitator) {
					/* Logged in as facilitator */
					this.setState({
						isLoggedIn: true,
						isLoading: false,
						userId: user.uid,
						isFacilitator: isFacilitator,
						email: user.email
					});
				} else {
					/* Logged in as group */
					this.setState({isLoggedIn: true, isLoading: false, userId: user.uid});
				}
			} else {
				this.setState({
					isLoading: false,
					isLoggedIn: false,
					userId: null,
					isFacilitator: false,
					isAdmin: false,
					email: null,
				});
			}
		});	
	};

	/**
	 * Toggle cookie popup
	 * @param {bool} showCookiePopup 
	 */
	toggleCookiePopup = (showCookiePopup) => {
		this.setState({showCookiePopup});
	};

	/**
	 * Accept / deny cookies
	 * @param {bool} isAccepted 
	 */
	toggleAcceptCookies = (isAccepted) => {
		if (isAccepted) {
			/* Cookies accepted, save choice in cookie */
			setCookie(appConfig.cookieConsentCookieName, 'ok');
			this.setState({cookiesAccepted: true});
		} else {
			/* Cookies denied, delete cookies (if any) and log out */
			deleteCookie(appConfig.cookieConsentCookieName);
			this.setState({cookiesAccepted: false}, () => {
				if (this.state.isLoggedIn) {
					this.handleLogout();
				}
			});
		}

		/* Close cookie popup */
		this.setState({showCookiePopup: false});
	};

	/**
	 * Facilitator login: Log in with email and password
	 * @param {string} email 
	 * @param {string} password 
	 */
	facilitatorLogin = (email, password) => {
		return firebase.auth().signInWithEmailAndPassword(email, password);
	};

	/**
	 * Group login: Find the game with the given game code
	 * @param {string} gameCode 
	 */
	findGame = (gameCode) => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			/* Get game */
			db.collection(appConfig.gamesDbName).doc(gameCode).get().then((doc) => {
				if (doc.exists) {
					/* Get groups of game */
					db.collection(appConfig.groupsDbName).where('gameId', '==', gameCode).get().then((docs) => {
						const groups = [];

						docs.forEach((doc) => {groups.push({id: doc.id, ...doc.data()});});
						resolve({status: 'success', groups: groups});
					}).catch((error) => {
						resolve({status: 'error', error: error});	
					});
				} else {
					resolve({status: 'error', error: 'gameDoesNotExist'});	
				}
			}).catch((error) => {
				resolve({status: 'error', error: error});	
			});	
		});	
	};

	/**
	 * Group login: Create new group and get login token
	 * @param {string} gameId 
	 * @param {string} groupName 
	 * @returns 
	 */
	createGroup = (gameId, groupName) => {
		return new Promise((resolve) => {
			apiHelper('group/create-group', {gameId, groupName}).then((response)=>{
				if (response.status === 'success' && response.token) {
					resolve({status: 'success', token: response.token});
				} else {
					resolve({status: 'error', error: response.error});
					// if (response.error === 'group-name-already-exists') {
				}
			}).catch((error) => {
				resolve({status: 'error', error});
			});
		});
	};

	/**
	 * Group login: Get a login token for selected group
	 * @param {number} gameId 
	 * @param {number} groupId 
	 */
	getLoginToken = (gameId, groupId) => {
		return new Promise((resolve) => {
			apiHelper('group/join-game', {gameId, groupId}).then((response)=>{
				if (response.status === 'success' && response.token) {
					resolve({status: 'success', token: response.token});
				} else {
					resolve({status: 'error', error: response.error});
				}
			}).catch((error) => {
				resolve({status: 'error', error});
			});
		});
	};

	/**
	 * Group login: Login with token
	 * @param {string} token 
	 */
	loginWithToken = (token) => {
		return firebase.auth().signInWithCustomToken(token);
	};

	/**
	 * Logout
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false,
			userId: null,
			isFacilitator: false,
			email: null,
		});
	};

	/**
	 * Set create user state
	 * @param {bool} isCreating 
	 */
	setCreateUser = (isCreating) => {
		this.setState({isCreatingUser: isCreating});
	};

	/**
	 * Set password reset state
	 * @param {bool} isResetting 
	 */
	setPasswordReset = (isResetting) => {
		this.setState({isResettingPassword: isResetting});
	};

	/**
 	* Render component
 	*/
	render = () => {	
		/* User is not loggged in */
		if (!this.state.isLoggedIn) {
			return (
				<>
					<Login 
						cookiesAccepted={this.state.cookiesAccepted}
						deviceInfo={this.props.deviceInfo}
						toggleCookiePopup={this.toggleCookiePopup}
						facilitatorLogin={this.facilitatorLogin}
						findGame={this.findGame}
						createGroup={this.createGroup}
						getLoginToken={this.getLoginToken}
						loginWithToken={this.loginWithToken}
					/>
					<Button 
						classes={['cookie', this.props.deviceInfo.orientation]} 
						onClick={() => {this.toggleCookiePopup(true);}} 
					/>
					{this.state.showCookiePopup && 
						<PopupConfirmCookies 
							deviceInfo={this.props.deviceInfo}
							toggleAcceptCookies={this.toggleAcceptCookies}
							toggleCookiePopup={this.toggleCookiePopup}
						/>
					}
				</>
			);
		}

		/* User is logged in, get controller component for facilitator / group */
		let UserComponent = (this.state.isFacilitator ? FacilitatorController : GroupController);
		return (
			<UserComponent 
				deviceInfo={this.props.deviceInfo}
				userId={this.state.userId}
				handleLogout={this.handleLogout}
			/>
		);
	};
}

LoginController.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
};

export default LoginController;