import {themesData} from 'data/themes-data';

/**
 * Get current game step id of group
 * @param {object} themeData 
 * @param {object} groupData 
 * @returns 
 */
function getCurrentGameStepId(themeData, groupData) {
	let currentGameStepId = themeData.gameSteps[0].id;

	if (
		groupData.currentGameStepId &&
		themeData.gameSteps.some((t) => {return t.id === groupData.currentGameStepId;})
	) {
		currentGameStepId = themeData.gameSteps.find((t) => {return t.id === groupData.currentGameStepId;}).id;
	}

	return currentGameStepId;
};


/**
 * Get group progress in percent
 * @param {string} themeId 
 * @param {object} groupData 
 * @returns 
 */
function getGroupProgress(themeId, groupData) {
	let progress = 0;

	const themeData = themesData.find((theme) => {return theme.id === themeId;});
	if (themeData && themeData.gameSteps && themeData.gameSteps.length > 0) {
		const gameStepId = getCurrentGameStepId(themeData, groupData);
		const gameStepIndex = themeData.gameSteps.findIndex((gs) => {return gs.id === gameStepId;});
		progress = Math.round((gameStepIndex / (themeData.gameSteps.length - 1)) * 100);
	}

	return progress;
};

/**
 * Get video id
 * Can depend on previous answers to questions
 * @param {object} gameStepData 
 * @param {object} groupData 
 * @returns 
 */
function getVideoId(gameStepData, groupData) {
	let videoId = null;

	if (gameStepData.videos && gameStepData.videos.length > 0) {
		if (gameStepData.videos.length === 1) {
			/* Only one video, use that */
			videoId = gameStepData.videos[0].id;
		} else {
		/* Multiple videos, check conditions */
			gameStepData.videos.forEach((video) => {
			/* Video id already found */
				if (videoId) return;

				if (video.conditions && video.conditions.length > 0) {
					let conditionsAreMet = true;
					video.conditions.forEach((condition) => {
						if (!conditionsAreMet) return;

						if (!checkIfConditionIsMet(condition, groupData)) {
							conditionsAreMet = false;
						}
					});

					if (conditionsAreMet) {
						videoId = video.id;
					}
				}
			});
		}
	}

	return videoId;
};

/**
 * Check if next btn is displayed,
 * Can depend on whether a group has answered certain questions
 * @param {object} gameStepData 
 * @param {object} groupData 
 * @returns 
 */
function checkIfNextBtnIsDisplayed(gameStepData, groupData) {
	let showNextStepBtn = (gameStepData.nextBtn ? true : false);

	if (
		showNextStepBtn && 
		gameStepData && 
		gameStepData.nextBtnConditions && 
		gameStepData.nextBtnConditions.length > 0
	) {
		gameStepData.nextBtnConditions.forEach((condition) => {
			if (!showNextStepBtn) return;

			if (!checkIfConditionIsMet(condition, groupData)) {
				showNextStepBtn = false;
			}
		});
	}

	return showNextStepBtn;
};

/**
 * Check if conditional next step id,
 * Can depend on whether a group has answered certain questions
 * @param {object} gameStepData 
 * @param {object} groupData 
 * @returns 
 */
function checkIfConditionalNextStepId(gameStepData, groupData) {
	let conditionsAreMet = false;
	if (
		gameStepData && 
		gameStepData.nextStepIdConditions && 
		gameStepData.nextStepIdConditions.length > 0
	) {
		gameStepData.nextStepIdConditions.forEach((condition) => {
			if (conditionsAreMet) return;

			if (checkIfConditionIsMet(condition, groupData)) {
				conditionsAreMet = true;
			}
		});
	}

	return conditionsAreMet;
};



/**
 * Check if a condition is met
 * @param {object} condition 
 * @param {object} groupData 
 */
function checkIfConditionIsMet(condition, groupData) {
	/* Question has been answered */
	if (condition.type === 'question-answered') {
		if (
			groupData.questions &&
			groupData.questions.some((q) => {
				return (q.questionId === condition.questionId && q.optionId !== null);
			})
		) {
			return true;
		}
	}


	/* Specific option selected */
	if (condition.type === 'question-option-selected') {
		if (
			groupData.questions &&
			groupData.questions.some((q) => {
				return (
					q.questionId === condition.questionId &&
					q.optionId === condition.optionId
				);
			})
		) {
			return true;
		}
	}

	return false;
};


/**
 * Get results for group surveys
 * @param {object} gameData 
 * @param {array} groupsData 
 * @returns 
 */
function getGameResults(gameData, groupsData) {
	/* Get theme data (default is first theme) */
	const themeData = (gameData.themeId 
		? themesData.find((t) => {return t.id === gameData.themeId;})
		: themesData[0]
	);

	/* Get results (each result is for a single page) */
	const results = (themeData.results ? JSON.parse(JSON.stringify(themeData.results)) : []);
	if (themeData.gameSteps && themeData.gameSteps.length > 0) {
		results.forEach((result) => {
			result.questions = [];
			/* Get relevant game step data for this result page */
			const gameStepData = themeData.gameSteps.find((step) => {
				return result.gameStepId === step.id;
			});
			if (
				gameStepData && 
				gameStepData.type === 'questions' && 
				gameStepData.questions && 
				gameStepData.questions.length > 0
			) {
				let totalOfAllQuestions = 0;
				gameStepData.questions.forEach((question) => {
					/* Get question data */
					const questionObj = {
						type: result.type, 
						questionId: question.id, 
						text: (question.text ? question.text : null), 
						options: [], 
						total: 0
					};
					/* Get data for all options of question */
					question.options.forEach((option) => {
						const optionObj = {optionId: option.id, text: (option.text ? option.text : null), value: 0,};
						questionObj.options.push(optionObj);
					});
					/* Get selected options from each group */
					groupsData.forEach((group) => {
						if (group && group.questions) {
							const groupQuestionData = group.questions.filter((q) => {
								return q.questionId === question.id;
							});
							if (groupQuestionData && groupQuestionData.length > 0) {
								groupQuestionData.forEach((questionData) => {
									questionObj.options.forEach((option) => {
										if (questionData[option.optionId]) {
											/* 
												Sum the number of times this option has been selected for this question
											*/
											option.value += questionData[option.optionId];

											/* Sum the number of times any option has been selected for this question */
											questionObj.total += questionData[option.optionId];

											/* Sum the total number of selected options for all questions */
											totalOfAllQuestions += questionData[option.optionId];
										}
									});
								});
							}
						}
					});
					result.questions.push(questionObj);
				});

				if (result.type === 'sum-all') {
					/* The "total" in the question objects should be the total of all questions */
					result.questions.forEach((question) => {
						question.total = totalOfAllQuestions;
					});
				}
			}
		});
	}

	return results;
}



export {
	getCurrentGameStepId,
	getGroupProgress,
	getVideoId,
	checkIfNextBtnIsDisplayed,
	getGameResults,
	checkIfConditionalNextStepId
};