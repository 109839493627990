import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText, renderMarkdown} from 'helpers/text-helper';
import {themesData} from 'data/themes-data';
import Button from 'components/ui/button/button';
import PopupResult from 'components/ui/popup-result/popup-result';
import './game-end.scss';

const GameEnd = ({deviceInfo, gameData, groupsData, goToPage}) => {
	/* Get theme data (default is first theme) */
	const themeData = (gameData.themeId 
		? themesData.find((t) => {return t.id === gameData.themeId;})
		: themesData[0]
	);

	/* Show result in popup */
	const [resultId, setResultId] = useState(null);

	const toggleResultPopup = (newResultId = null) => {
		if (!newResultId || resultId === newResultId) {
			setResultId(null);
		} else {
			setResultId(newResultId);
		}
	};


	return (
		<div className='GameEnd'>
			{/* Game title */}
			<div className='GameEnd-gameTitle' onClick={() => {goToPage('settings');}}>
				<span>{getText('generalTexts', 'gameTitle')}</span>
			</div>

			{/* Content */}
			<div className='GameEnd-content'>
				<div className='GameEnd-panel text'>
					<div className='GameEnd-title'>
						<span>{getText('generalTexts', 'furtherDiscussion')}</span> 
					</div>
					<div className='GameEnd-text'>
						{renderMarkdown(themeData.furtherDiscussion)}
					</div>
				</div>

				<div className='GameEnd-panelWrap'>
					<div className='GameEnd-panel result'>
						<div className='GameEnd-resultWrap'>
							<div className="GameEnd-resultIcon result-1" />
							<div className="GameEnd-resultBtn">
								<Button 
									classes={['pink', 'fullHeight']}
									text={getText('generalTexts', 'viewResult1')}
									onClick={() => {toggleResultPopup('result-1');}}
								/>
							</div>
						</div>
					</div>

					<div className='GameEnd-panel result'>
						<div className='GameEnd-resultWrap'>
							<div className="GameEnd-resultIcon result-2" />
							<div className="GameEnd-resultBtn">
								<Button 
									classes={['pink', 'fullHeight']}
									text={getText('generalTexts', 'viewResult2')}
									onClick={() => {toggleResultPopup('result-2');}}
								/>
							</div>
						</div>
					</div>

				</div>
			</div>


			{/* Back btn */}
			<div className={'GameEnd-prevBtn'}>
				<Button 
					classes={['pink', 'fullHeight']}
					text={getText('generalTexts', 'back')}
					onClick={() => {goToPage('game-results');}}
				/>
			</div>

			{/* Create new game btn */}
			<div className={'GameEnd-nextBtn'}>
				<Button 
					classes={['pink', 'fullHeight']}
					text={getText('generalTexts', 'newGame')}
					onClick={() => {goToPage('create-game');}}
				/>
			</div>

			{resultId && <PopupResult 
				deviceInfo={deviceInfo}
				resultId={resultId}
				gameData={gameData}
				groupsData={groupsData}
				toggleResultPopup={toggleResultPopup}

			/>}
		</div>
	);
};

GameEnd.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	groupsData: PropTypes.array.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default GameEnd;