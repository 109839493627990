import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import Loading from 'components/loading/loading';
import Settings from 'components/settings/settings';
import CreateGame from 'components/create-game/create-game';
import GameInfo from 'components/game-info/game-info';
import GameQuote from 'components/game-quote/game-quote';
import GameStatus from 'components/game-status/game-status';
import GameResults from 'components/game-results/game-results';
import GameEnd from 'components/game-end/game-end';

class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			page: 'settings',
			game: null,
			groups: [],
			loadErrMsg: null,
		};
		this.unsubscribeGame = null;
		this.unsubscribeGroups = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Subscribe to faclitator's game */
		this.subscribeToGame().then(() => {
			if (this.state.game) {
				this.setState({isLoading: false});
			} else {
				this.setState({isLoading: false, page: 'create-game'});
			}
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from game */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();

		/* Unsubscribe from groups */
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
	};

	/**
	 * Create new game 
	 * Delete old game and its groups (if they exist)
	 */
	createNewGame = (themeId) => {
		return new Promise((resolve) => {
			apiHelper('facilitator/create-game', {
				facilitatorId: this.props.userId,
				themeId: themeId,
				currentPageId: 't1-s1'
			}).then((response) => {
				if (response.status === 'success') {
					/* New game created, subscribe to new game (and its groups) */
					this.subscribeToGame().then(() => {
						resolve({status: 'success'});
					}).catch((error) => {
						/* Error: Game subscription error */
						resolve({status: 'error', error});
					});
				} else {
					/* Error: New game not created */
					resolve({status: 'error', error: response.error.message});
				}
			}).catch((error) => {
				//* Error: New game not created */
				resolve({status: 'error', error: error.error.message});
			});
		});
	};

	/**
	 * Subscribe to game
	 */
	subscribeToGame = () => {
		/* Cancel previous subscription */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			const query = db.collection(appConfig.gamesDbName).where('facilitatorId', '==', this.props.userId);			
			this.unsubscribeGame = query.onSnapshot((querySnapshot) => {
				let game = null;
				querySnapshot.forEach((doc) => {
					if (game) return;
					game = {id: doc.id, ...doc.data()};
				});
				const startNewGroupSubscription = (game && (!this.state.game || this.state.game.id !== game.id) 
					? true : false);
				this.setState({game: game}, () => {
					if (startNewGroupSubscription) {
						this.subscribeToGroups(game.id).then(() => {
							resolve({status: 'success'});	
						});
					} else {
						resolve({status: 'success'});
					}
				});
			});
		});
	};

	/**
	 * Subscribe to groups of game
	 */
	subscribeToGroups = (gameId) => {
		/* Cancel previous subscription */
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		
		/* Subscribe  */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGroups = db.collection(appConfig.groupsDbName).where('gameId', '==', gameId)
				.onSnapshot((querySnapshot) => {
					let groups = [];
					querySnapshot.forEach((doc) => {groups.push({id: doc.id, ...doc.data()});});
					this.setState({groups: groups}, () => {resolve({status: 'success'});});
				});			
		});
	};


	/**
	 * Go to page
	 * @param {string} page 
	 */
	goToPage = (page) => {
		this.setState({page: page});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading 
					loadErrMsg={this.state.loadErrMsg}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};

		/* Settings (continue or start new game) */
		if (this.state.page === 'settings') {
			return (
				<Settings
					deviceInfo={this.props.deviceInfo}
					gameData={this.state.game}
					goToPage={this.goToPage}
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		if (this.state.page === 'create-game') {
			return (
				<CreateGame 
					gameData={this.state.game}
					goToPage={this.goToPage} 
					handleLogout={this.props.handleLogout} 
					createNewGame={this.createNewGame}
				/>
			);
		}

		/* Game info */
		if (this.state.page === 'game-info' && this.state.game) {
			return (
				<GameInfo goToPage={this.goToPage} />
			);
		}

		/* Game quote */
		if (this.state.page === 'game-quote' && this.state.game) {
			return (
				<GameQuote gameData={this.state.game} goToPage={this.goToPage} />
			);
		}

		/* Game status */
		if (this.state.page === 'game-status' && this.state.game) {
			return (
				<GameStatus 
					deviceInfo={this.props.deviceInfo}
					gameData={this.state.game}
					groupsData={this.state.groups}
					goToPage={this.goToPage}
				/>
			);
		}

		/* Game results */
		if (this.state.page === 'game-results' && this.state.game) {
			return (
				<GameResults 
					gameData={this.state.game}
					groupsData={this.state.groups}
					goToPage={this.goToPage}
				/>
			);
		}

		/* Game end */
		if (this.state.page === 'game-end' && this.state.game) {
			return (
				<GameEnd 
					deviceInfo={this.props.deviceInfo}
					gameData={this.state.game}
					groupsData={this.state.groups}
					goToPage={this.goToPage}
				/>
			);
		}

		/* No match, default page is loading */
		return (
			<Loading 
				loadErrMsg={this.state.loadErrMsg}
				handleLogout={this.props.handleLogout} 
			/>
		);
	};
}

FacilitatorController.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
};

export default FacilitatorController;
