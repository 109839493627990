import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import './multiple-choice.scss';

const MultipleChoice = ({deviceInfo, question, groupData, handleAnswerQuestion}) => {
	/* Group question data */
	const groupQuestionData = (groupData.questions 
		? groupData.questions.find((q) => {return (q.questionId === question.id);})
		: null
	);

	/* Loading status */
	const [isSelectingOptionId, setIsSelectingOptionId] = useState(null);

	/* Selected option id */
	const [selectedOptionId, setSelectedOptionId] = useState(groupQuestionData ? groupQuestionData.optionId : null);

	/**
	 * Update selected option id if it is updated on the server (i.e. by another group member)
	 */
	useEffect(() => {
		if (groupQuestionData && groupQuestionData.optionId !== selectedOptionId) {
			setSelectedOptionId(groupQuestionData.optionId);
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupQuestionData]);

	/**
	 * Player clicked on an option
	 * @param {bool} isSelected 
	 * @param {string} optionId 
	 * @returns 
	 */
	const handleOptionClick = (isSelected, optionId) => {
		/* Already selected or in the middle of selecting - return */
		if (isSelected || isSelectingOptionId) return;
		
		/* Update local state */
		setIsSelectingOptionId(optionId);
		setSelectedOptionId(optionId);
		
		/* Update server */
		handleAnswerQuestion('mc', question.id, question.type, optionId).then(() => {
			setIsSelectingOptionId(null);
		});
	};

	return (
		<div className={'MultipleChoice ' + deviceInfo.orientation}>
			<div className="MultipleChoice-text">
				{renderMarkdown(question.text)}
			</div>
			<div className="MultipleChoice-options">
				{question.options.map((option) => {
					const isSelected = (option.id === selectedOptionId);
					return (
						<div 
							key={option.id} 
							className={'MultipleChoice-option' + (isSelected ? ' selected' : '') 
								+ (isSelectingOptionId === option.id ? ' loading' : '')}
							onClick={() => {handleOptionClick(isSelected, option.id);}}
						>
							{renderMarkdown(option.text)}
							{isSelected && <div className="MultipleChoice-checkmark" />}
						</div>
					);
				})}
			</div>
		</div>
	);
};

MultipleChoice.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	question: PropTypes.object.isRequired,
	groupData: PropTypes.object.isRequired,
	handleAnswerQuestion: PropTypes.func.isRequired
};

export default MultipleChoice;