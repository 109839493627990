import React from 'react';
import PropTypes from 'prop-types';
import './company-logos.scss';

const CompanyLogos = ({deviceInfo}) => {

	return (
		<div className={'CompanyLogos ' + deviceInfo.orientation}>
			<div className="CompanyLogos-logo egmont" />
			<div className="CompanyLogos-logo sko" />
			<a 
				href="https://cphgamelab.dk" 
				target="_blank" 
				className="CompanyLogos-logo cgl" 
				rel="noreferrer"
			>Copenhagen Game Lab</a>
		</div>
	);
};

CompanyLogos.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
};

export default CompanyLogos;