const gameSteps = [
	{
		id: 't4-video1',
		type: 'video',
		title: 'Se videoen. Tryk "videre" for at vælge mellem forskellige muligheder.',
		text: `Pia og Stines børn er til gymnastik på skolen. Imens taler de to mødre sammen om en diskussion, der kører blandt forældrene på Aula.`,
		videos: [
			{id: 'kcNQjuPTZSQ'}
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	{
		id: 't4-question1',
		type: 'questions',
		text: 'Diskutér dilemmaet',
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [
			{type: 'question-answered', questionId: 't4-question1-question'}
		],
		nextStepId: 't4-video3',
		nextStepIdConditions: [
				{type: 'question-option-selected', questionId: 't4-question1-question', optionId: 2}
			],
		questions: [
			{
				id: 't4-question1-question',
				type: 'multiple-choice',
				text: 'Hvad synes I, der videre skal ske? I har følgende valgmuligheder:',
				options: [
					{
						id: 1,
						text: 'Stine skal tale med Jakob om, at det ikke er i orden at skrive dårligt om andres børn på Aula.',
					},
					{
						id: 2,
						text: 'Pia skal tale med forældrene til drengene, der legede vildt til fødselsdagen, for at høre deres oplevelse.',
					},
				]
			}
		]
	},
	{
		id: 't4-video2',
		type: 'video',
		title: 'Se videoen. Tryk "videre" for at vælge mellem forskellige muligheder.',
		text: `Ved glemmekassen møder Stine Jakob, der startede diskussionen på Aula.`,
		videos: [
			{
				id: 'DfMD7XNzIf4', 
			},
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	{
		id: 't4-question2',
		type: 'questions',
		text: 'Diskutér dilemmaet',
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [
			{type: 'question-answered', questionId: 't4-question2-question'}
		],
		questions: [
			{
				id: 't4-question2-question',
				type: 'multiple-choice',
				text: 'Hvad synes I, der videre skal ske? I har følgende valgmuligheder:',
				options: [
					{
						id: 1,
						text: 'Tvillingernes forældre skal tage mere ansvar i forhold til drengenes opførsel ved børnefødselsdage.',
					},
					{
						id: 2,
						text: 'Forældrene skal samarbejde om en løsning, som gør det muligt for alle at deltage i kommende børnefødselsdage.',
					},
				]
			}
		]
	},

	{
		id: 't4-video3',
		type: 'video',
		title: 'Se videoen. Tryk bagefter videre.',
		text: `Pia og Stine er til et arrangement på skolen, hvor de sidder og afprøver klassens matematikværksteder. De taler videre om diskussionen på Aula.`,
		videos: [
			{id: 'IfNCvKysoGU'}
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	// SURVEYS
	{
		id: 't4-survey1-intro',
		type: 'text',
		title: null,
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Nu kommer der to spørgsmål om jeres individuelle holdninger til emnet.
			<br /><br />Læs spørgsmålet og svar på skift. Lad telefonen gå på omgang.`
	},
	{
		id: 't4-survey1',
		type: 'questions',
		layout: 'evaluate-1',
		text: 'Hvad skal vi tænke på, når der opstår uenigheder i forældregruppen?',
		subText: 'Tryk på to svar. Tryk så OK og giv telefonen til den næste spiller.',
		prevBtn: 'Tilbage',
		nextBtn: 'OK',
		nextBtnConditions: [],
		maxAnswers: {
			type: 'all-questions',
			perPlayer: 2
		},
		questions: [
			{
				id: 't4-survey1-q1',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi husker på, at der kan være mange årsager bag handlinger.'}]
			},
			{
				id: 't4-survey1-q2',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Det er et fælles anliggende at få alle forældre med i samarbejdet omkring klassens fællesskab.'}]
			},
			{
				id: 't4-survey1-q3',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Uenigheder opstår naturligt, og skal have lov at være der og folde sig ud.'}]
			},
			{
				id: 't4-survey1-q4',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi vil have et fællesskab i forældregruppen, hvor vi også kan tale om det, som er svært og sårbart.'}]
			},
			{
				id: 't4-survey1-q5',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi undgår at tale nedladende om andre børn og forældre.'}]
			},
		]
	},
	{
		id: 't4-survey2-intro',
		type: 'text',
		title: null,
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Det var det første spørgsmål.
			<br /><br />Klik på "Videre" og gå til næste spørgsmål.`
	},
	{
		id: 't4-survey2',
		type: 'questions',
		layout: 'evaluate-2',
		text: `Hvad synes du, der skal tages højde for, når vi skal skabe et trygt og tillidsfuldt forældresamarbejde?`,
		subText: `Svar ja tak, måske eller nej tak til følgende muligheder. Tryk så OK og giv telefonen videre.`,
		prevBtn: 'Tilbage',
		nextBtn: 'OK',
		nextBtnConditions: [],
		questions: [
			{
				id: 't4-survey2-q1',
				type: 'evaluate',
				text: 'Vi tager kontakt til forældre, som af en eller anden årsag står udenfor fællesskabet.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},
			{
				id: 't4-survey2-q2',
				type: 'evaluate',
				text: 'Vi taler lige ud af posen til hinanden og pakker ikke tingene ind.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},{
				id: 't4-survey2-q3',
				type: 'evaluate',
				text: 'Vi undgår at skrive på Aula om konflikter og taler sammen i stedet for.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},{
				id: 't4-survey2-q4',
				type: 'evaluate',
				text: 'Vi tager direkte kontakt til de involverede forældre og undgår at inddrage hele forældregruppen.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},{
				id: 't4-survey2-q5',
				type: 'evaluate',
				text: 'Vi orienterer skolen om konflikter forældrene imellem.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			}
		]
	},
	{
		id: 't4-survey1-diskussion',
		type: 'text',
		title: 'Diskussion',
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Slut af med kort at diskutere følgende spørgsmål i gruppen:
		<br /><br />
		Hvad er vigtigt i forhold til samarbejde i forældregruppen?
		<br /><br />
		Brug max fem minutter på det og tryk så videre.`
	},
	{
		id: 't4-finish',
		type: 'finish',
		prevBtn: null,
		nextBtn: null,
	}
];
	

export {
	gameSteps
};