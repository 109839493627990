import React from 'react';
import PropTypes from 'prop-types';
import {getText, renderMarkdown} from 'helpers/text-helper';
import {themesData} from 'data/themes-data';
import Button from 'components/ui/button/button';
import './game-quote.scss';

const GameQuote = ({gameData, goToPage}) => {

	const themeId = gameData.themeId;
	const themeData = themesData.find((t) => {return t.id === themeId;});
	const text = (themeData && themeData.quote
		? themeData.quote
		: ''
	);

	return (
		<div className='GameQuote'>
			{/* Game title */}
			<div className='GameQuote-gameTitle' onClick={() => {goToPage('settings');}}>
				<span>{getText('generalTexts', 'gameTitleSplit')}</span>
			</div>

			{/* Content */}
			<div className='GameQuote-content'>
				<div className={'GameQuote-image ' + themeId} />
				<div className='GameQuote-text'>
					{renderMarkdown(text)}
				</div>

			</div>


			{/* Next btn */}
			<div className='GameQuote-nextBtn'>
				<Button
					text={getText('generalTexts', 'continue')}
					classes={['pink', 'fullHeight']}
					onClick={() => {goToPage('game-status');}}
				/>
			</div>

		</div>
	);
};

GameQuote.propTypes = {
	gameData: PropTypes.object.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default GameQuote;