const gameSteps = [
	{
		id: 't1-video1',
		type: 'video',
		title: 'Se videoen. Tryk "videre" for at vælge mellem forskellige muligheder.',
		text: `Kontaktforældrene i 0.A holder møde. De er næsten færdige med at arrangere det første klassearrangement. En af forældrene må tage en telefon. Imens fortsætter Sara og Ditte snakken.`,
		videos: [
			{id: 'dhZAmqKMlaA'}
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	{
		id: 't1-question1',
		type: 'questions',
		text: 'Diskutér dilemmaet',
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [
			{type: 'question-answered', questionId: 't1-question1-question'}
		],
		questions: [
			{
				id: 't1-question1-question',
				type: 'multiple-choice',
				text: 'Hvad synes I, der videre skal ske med klassearrangementet i 0.A? I har følgende valgmuligheder:',
				options: [
					{
						id: 1,
						text: 'De bliver nødt til at ændre arrangementet, så de ikke risikerer at udelukke familier på grund af penge.',
					},
					{
						id: 2,
						text: 'Det er vigtigere at gøre det nemt for alle at deltage. De kan sagtens finde en løsning omkring pengene.',
					},
				]
			}
		]
	},
	{
		id: 't1-video2',
		type: 'video',
		title: 'Se videoen. Tryk "videre" for at vælge mellem forskellige muligheder.',
		text: 'Sara og Ditte forsøger at finde den bedste løsning.',
		videos: [
			{
				id: 'rs75Wosd9XA', 
				conditions: [{type: 'question-option-selected', questionId: 't1-question1-question', optionId: 1}]
			},
			{
				id: '0Jx13Nlosdk', 
				conditions: [{type: 'question-option-selected', questionId: 't1-question1-question', optionId: 2}]
			},
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	{
		id: 't1-question2',
		type: 'questions',
		text: 'Diskutér dilemmaet',
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [
			{type: 'question-answered', questionId: 't1-question2-question'}
		],
		questions: [
			{
				id: 't1-question2-question',
				type: 'multiple-choice',
				text: 'Ditte og Sara kan ikke blive enige. Hvilke forslag synes I, de skal fremlægge for de andre kontaktforældre?',
				options: [
					{
						id: 1,
						text: 'At forældrene medbringer mad hjemmefra, så de helt undgår betaling.',
					},
					{
						id: 2,
						text: 'At de gør det praktisk nemt for alle at deltage og finder en løsning for dem, der synes, det er mange penge.',
					},
				]
			}
		]
	},
	{
		id: 't1-video3',
		type: 'video',
		title: 'Se videoen. Tryk bagefter videre.',
		text: 'Sara og Ditte vender tilbage til mødet for at tale om dilemmaet. ',
		videos: [
			{id: 'wXvx-TlBkL0'}
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: [],
	},
	{
		id: 't1-survey1-intro',
		type: 'text',
		title: null,
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Nu kommer der to spørgsmål om jeres individuelle holdninger til emnet.
			<br /><br />Læs spørgsmålet og svar på skift. Lad telefonen gå på omgang.`
	},
	// SURVEYS
	{
		id: 't1-survey1',
		type: 'questions',
		layout: 'evaluate-1',
		text: `Hvad skal vi tænke på ved sociale arrangementer i vores klasse?`,
		subText: `Tryk på to svar. Tryk så OK og giv telefonen til den næste spiller.`,
		prevBtn: 'Tilbage',
		nextBtn: 'OK',
		nextBtnConditions: [],
		maxAnswers: {
			type: 'all-questions',
			perPlayer: 2
		},
		questions: [
			{ 
				id: 't1-survey1-q1',
				type: 'evaluate',
				options: [
					{id: 'option-1', text: 'Arrangementer må ikke koste penge.'}
				]
			},
			{
				id: 't1-survey1-q2',
				type: 'evaluate',
				options: [
					{id: 'option-1', text: 'Der skal være få og overskuelige arrangementer.'}
				]
			},{
				id: 't1-survey1-q3',
				type: 'evaluate',
				options: [
					{id: 'option-1', text: 'Arrangementer planlægges, så alle kan deltage.'}
				]
			},{
				id: 't1-survey1-q4',
				type: 'evaluate',
				options: [
					{id: 'option-1', text: 'Sociale arrangementer afholdes i skoletiden og på skolens område.'}
				]
			},{
				id: 't1-survey1-q5',
				type: 'evaluate',
				options: [
					{id: 'option-1', text: 'Forældre skal ikke stå for at afholde arrangementer.'}
				]
			}
		]
	},
	{
		id: 't1-survey2-intro',
		type: 'text',
		title: null,
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Det var det første spørgsmål.
			<br /><br />Klik på "Videre" og gå til næste spørgsmål.`
	},
	{
		id: 't1-survey2',
		type: 'questions',
		layout: 'evaluate-2',
		text: `Hvad synes du, der skal tages højde for, hvis I planlægger sociale arrangementer?`,
		subText: `Svar ja tak, måske eller nej tak til følgende muligheder. Tryk så OK og giv telefonen videre.`,
		prevBtn: 'Tilbage',
		nextBtn: 'OK',
		nextBtnConditions: [],
		questions: [
			{
				id: 't1-survey2-q1',
				type: 'evaluate',
				text: 'Alle arrangementer skal være for både børn og forældre.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},
			{
				id: 't1-survey2-q2',
				type: 'evaluate',
				text: 'Der skal også være arrangementer KUN for forældre.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},{
				id: 't1-survey2-q3',
				type: 'evaluate',
				text: 'Børnefødselsdage holdes, som hver enkel familie ønsker.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},{
				id: 't1-survey2-q4',
				type: 'evaluate',
				text: 'Børnefødselsdage holdes fælles f.eks. en gang i kvartalet.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},{
				id: 't1-survey2-q5',
				type: 'evaluate',
				text: 'Vi dropper helt at afholde sociale arrangementer.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			}
		]
	},
	{
		id: 't1-survey1-diskussion',
		type: 'text',
		title: 'Diskussion',
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Slut af med kort at diskutere følgende spørgsmål i gruppen:  
		<br /><br />
		Hvad er vigtigt at tænke på i forhold til sociale arrangementer i klassen?
		<br /><br />
		Brug max fem minutter på det og tryk så videre.`
	},
	{
		id: 't1-finish',
		type: 'finish',
		prevBtn: null,
		nextBtn: null,
	}
];
	

export {
	gameSteps
};