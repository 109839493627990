import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText, renderMarkdown} from 'helpers/text-helper';
import {themesData} from 'data/themes-data';
import Button from 'components/ui/button/button';
import './create-game.scss';

const CreateGame = ({gameData, createNewGame, goToPage, handleLogout}) => {
	/* Loading status */
	const [isLoading, setIsLoading] = useState(false);

	/* Error message */
	const [errMsg, setErrMsg] = useState(null);

	/**
	 * Creates a new game, and if successful, go to game status page
	 */
	const handleCreateGame = (themeId) => {
		if (isLoading) return;

		setIsLoading(true);
		createNewGame(themeId).then((response) => {
			if (response.status === 'success') {
				setIsLoading(false);
				goToPage('game-info');
			} else {
				setErrMsg(response.error);
				setIsLoading(false);
			}
		});
	};

	return (
		<div className='CreateGame'>
			{/* Game title */}
			<div className='CreateGame-gameTitle' onClick={() => {goToPage('settings');}}>
				<span>{getText('generalTexts', 'gameTitleSplit')}</span>
			</div>

			{/* Manual btn */}
			<div className="CreateGame-manualBtn">
				<Button 
					text={getText('generalTexts', 'teacherManual')}
					classes={['pink', 'fullHeight']}
					type="download"
					href="/vejledning.pdf"
				/>
			</div>

			{/* Content */}
			<div className='CreateGame-content'>
				<div className='CreateGame-title'>
					<span>{getText('pageTexts', 'aboutTheGameTitle')}</span> 
				</div>
				<div className='CreateGame-text'>
					{renderMarkdown(getText('pageTexts', 'aboutTheGame'))}
				</div>
				<div className='CreateGame-themes'>
					{themesData.map((theme, index) => {
						return (
							<div 
								key={index} 
								className={'CreateGame-theme ' + theme.id + (isLoading ? ' loading' : '')}
								onClick={() => {handleCreateGame(theme.id);}}
							>
								<div className={'CreateGame-themeName'}>
									<span>{theme.titleShort}</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* Error msg */}
			<div className='CreateGame-feedback'>
				{errMsg && <span>{errMsg}</span>}
			</div>

			{/* Buttons */}
			<div className='CreateGame-buttons'>
				{/* Back btn */}
				{gameData && <div className='CreateGame-button'>
					<Button
						text={getText('generalTexts', 'back')}
						classes={['pink', 'fullHeight']}
						onClick={() => {goToPage('settings');}}
					/>
				</div>}
			</div>

			{/* Logout btn */}
			<div className='CreateGame-logoutBtn'>
				<Button
					text={getText('generalTexts', 'logout')}
					classes={['pink']}
					onClick={() => {handleLogout();}}
				/>
			</div>
		</div>
	);
};

CreateGame.propTypes = {
	gameData: PropTypes.object,
	goToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	createNewGame: PropTypes.func.isRequired,
};

export default CreateGame;