

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import {getGroupProgress} from 'helpers/game-helper';
import Button from 'components/ui/button/button';
import PopupGroupWarning from 'components/ui/popup-group-warning/popup-group-warning';
import QR from 'components/ui/qr/qr';
import './game-status.scss';

const GameStatus = ({deviceInfo, gameData, groupsData, goToPage}) => {
	/* Get theme id */
	const themeId = (gameData.themeId ? gameData.themeId : 'theme-1');

	/* Hide / show warning popup */
	const [showWarningPopup, setShowWarningPopup] = useState(false);

	/**
	 * Check if all groups have completed the game
	 */
	const handleGoToNextPage = () => {
		let allGroupsCompleted = true;
		groupsData.forEach((g) => {
			if (!allGroupsCompleted) return;

			const groupProgress = getGroupProgress(themeId, g);
			if (groupProgress < 100) allGroupsCompleted = false;
			
		});

		if (allGroupsCompleted) {
			goToNextPage();
		} else {
			setShowWarningPopup(true);
		}
		
		
	};

	/**
	 * Go to next page
	 */
	const goToNextPage = () => {
		goToPage('game-results');
	};

	return (
		<div className={'GameStatus' + (groupsData.length !== 0 ? ' hasGroups' : '')}>
			{/* Game title */}
			<div className='GameStatus-gameTitle' onClick={() => {goToPage('settings');}}>
				<span>{getText('generalTexts', 'gameTitleSplit')}</span>
			</div>

			{/* Content */}
			<div className="GameStatus-content">
				<div className='GameStatus-title'>
					<span>{getText('generalTexts', 'gameStatusTitle')}</span>
				</div>
				<div className={'GameStatus-groups' + (groupsData.length === 0 ? ' waiting' : '')}>
					{groupsData.length === 0 && <div className="GameStatus-placeholder">
						<div className="GameStatus-placeholderGroup" />
						<div className="GameStatus-placeholderText">
							<span>{getText('generalTexts', 'waitingForGroups')}</span>
						</div>
						
					</div>}
					{groupsData.map((group) => {
						const groupProgress = getGroupProgress(themeId, group);
						return (
							<div key={group.id} className="GameStatus-group">
								<div className="GameStatus-groupName"><span>{group.name}</span></div>
								<div className="GameStatus-groupProgressTrack">
									<div className="GameStatus-groupProgressBar" style={{width: groupProgress + '%'}} />
								</div>
							</div>
						);
					})}
				</div>
				<div className="GameStatus-qrInfo">
					<span>{getText('generalTexts', 'qrText')}</span>
				</div>
				<div className='GameStatus-loginQR'>
					<QR gameCode={gameData.id} url={'https://' + appConfig.gameUrl + '/spiller'}/>
				</div>
			</div>
			<div className="GameStatus-nextBtn">
				<Button 
					classes={['pink', 'fullHeight']}
					text={getText('generalTexts', 'goToResults')}
					onClick={() => {handleGoToNextPage();}}
				/>
			</div>

			{showWarningPopup && 
				<PopupGroupWarning 
					deviceInfo={deviceInfo}
					goToNextPage={goToNextPage}
					setShowWarningPopup={setShowWarningPopup}
				/>}
		</div>
	);
};

GameStatus.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	groupsData: PropTypes.array.isRequired,
	goToPage: PropTypes.func.isRequired,
};

export default GameStatus;