import React from 'react';
import PropTypes from 'prop-types';
import {getGameResults} from 'helpers/game-helper';
import {sortArrayByProperty} from '../../../helpers/array-helper';
import PieChart from 'components/ui/pie-chart/pie-chart';
import './result.scss';

const Result = ({resultId, gameData, groupsData}) => {
	/* Get results */
	const resultsData = getGameResults(gameData, groupsData);
	const currentResultData = resultsData.find((r) => {return r.id === resultId;});
	
	/* Row chart */
	let maxAnswersInAQuestion = 0;
	let sortedResults = [];
	if (currentResultData && 
		currentResultData.type === 'sum-all' &&
		currentResultData.questions &&
		currentResultData.questions.length > 0 &&
		groupsData
	) {
		currentResultData.questions.forEach((question) => {
			if (question.options && question.options.length > 0) {
				const answer = question.options[0].value;
				question.answer = answer;
				if (answer > maxAnswersInAQuestion) {
					maxAnswersInAQuestion = answer;
				}
			}
		});
		sortedResults = sortArrayByProperty(currentResultData.questions, 'answer', 'DESC');
	}

	if (!currentResultData) return null;

	return (
		<div className={'Result ' + currentResultData.type 
			+ (currentResultData.questions ? ' questions-' + currentResultData.questions.length : '')}
		>
			{/* Result text */}
			{(currentResultData && currentResultData.text) && <div className="Result-text">
				<span>{currentResultData.text}</span>
			</div>}

			{/* Sum-all */}
			{currentResultData.type === 'sum-all' && <div className="Result-result sum-all">
				<div className="Result-rowChart">
					{sortedResults && sortedResults.map((question, index) => {
						let questionText = null;
						let percent = 0;
						let value = 0;
						if (question.options && question.options.length > 0) {
							questionText = question.options[0].text;
							if (question.total && question.total > 0) {
								value = question.options[0].value;
								percent = Math.round(100 * value / maxAnswersInAQuestion);
							}
						}
						return (
							<div key={index} className={'Result-rowChartQuestion label-' + (index + 1)}>
								<div className="Result-rowChartLabel">
									<span>{questionText}</span>
								</div>
								<div className="Result-rowChartResultWrap">
									<div 
										style={{width: percent + '%'}}
										className={'Result-rowChartResult label-' + (index + 1)}
									>
										
									</div>
									<div className="Result-rowChartResultValue"><span>{value}</span></div>
								</div>
							</div>
						);
					})}
				</div>
			</div>}

			{/* Sum-each */}
			{currentResultData.type === 'sum-each' && <div className="Result-result sum-each">
				<div className="Result-questions">
					{(currentResultData && currentResultData.questions) && 
							currentResultData.questions.map((question, index) => {
								let values = [];
								if (
									question.total && 
									question.total > 0 &&
									question.options && 
									question.options.length > 0
								) {
									question.options.forEach((option) => {
										const percent = Math.round(100 * option.value / question.total);
										values.push(percent);
									});
								}
								return (
									<div key={index} className="Result-question">
										{/* Question text */}
										<div className="Result-questionText"><span>{question.text}</span></div>

										{/* Pie chart */}
										<div className="Result-pieChart">
											<PieChart values={values} />
										</div>										
									</div>
								);
							})
					}
				</div>
				{(
					currentResultData && 
						currentResultData.questions &&
						currentResultData.questions.length > 0 &&
						currentResultData.questions[0].options 
				) && <div className="Result-labels">
					{currentResultData.questions[0].options.map((option, index) => {
						return (
							<div 
								key={index} 
								className={'Result-label label-' + (index + 1)}
							>
								<span>{option.text}</span>
							</div>
						);
					})}
				</div>}
							
			</div>}
		</div>
	);
};

Result.propTypes = {
	resultId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	groupsData: PropTypes.array.isRequired
};

export default Result;
