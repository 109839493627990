import React from 'react';
import PropTypes from 'prop-types';
import './logo.scss';

const Logo = ({page}) => {
	return (
		<div className={'Logo ' + page} />
	);
};

Logo.propTypes = {
	page: PropTypes.string.isRequired
};

export default Logo;