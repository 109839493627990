import React, {useState} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getText } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './reset-password.scss';

const ResetPassword = ({setFacilitatorAction}) => {
	const [email, setEmail] = useState('');
	const [feedback, setFeedback] = useState(null);
	const [isResettingPassword, setIsResettingPassword] = useState(false);
	
	/**
	 * Handles email input and resets feedback
	 * @param {object} event 
	 */
	const handleEmailInput = (event) => {
		let value = event.target.value;

		setFeedback(null);

		setEmail(value);
	};
	/**
	 * Sends email for resetting password to given email
	 * @param {object} event 
	 * @returns 
	 */
	const resetPassword = (event) => {
		if (event) event.preventDefault();
		
		setFeedback(null);
		if (!email) {
			let errorMsg = getText('errorTexts', 'invalidEmail');
			setFeedback(errorMsg);
			return;
		}

		setIsResettingPassword(true);
		const auth = firebase.auth();
		auth.sendPasswordResetEmail(email)
			.then(()=>{
				setIsResettingPassword(false);
				setFacilitatorAction('login');
			})
			.catch((error) =>{
				let errorMsg = 'unknownError';
				if (error.code === 'auth/invalid-email') errorMsg = getText('errorTexts', 'invalidEmail');
				if (error.code === 'auth/user-not-found') errorMsg = getText('errorTexts', 'emailNotFound');

				setFeedback(errorMsg);
			});
	};

	return (
		<div className='ResetPassword'>
			<form className="ResetPassword-form" onSubmit={resetPassword}>
				<div className="ResetPassword-label">
					<span>{getText('generalTexts', 'resetPasswordMessage')}</span>
				</div>
				<input
					className={'ResetPassword-input'}
					name="email"
					type="text"
					autoComplete="section-resetpassword email"
					value={email ? email : ''}
					onChange={(event)=>{handleEmailInput(event);}}
				/>
				<div className='ResetPassword-confirmButton'>
					<Button
						type="submit"
						isLoading={isResettingPassword}
						classes={['pink', 'fullHeight']}
						text={getText('generalTexts', 'reset')}
					/>
				</div>
				<div className="ResetPassword-errorMessage">
					<span>{feedback}</span>
				</div>
				<div 
					className="ResetPassword-backButton"
					onClick={()=>{setFacilitatorAction('login');}}
				>
					<span>{getText('generalTexts', 'backToLogin')}</span>
				</div>
				
			</form>
		</div>
	);
};

ResetPassword.propTypes = {
	setFacilitatorAction: PropTypes.func.isRequired,
};

export default ResetPassword;