import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import Game from 'components/game/game';
import Loading from 'components/loading/loading';
import PopupGroupPlayers from 'components/ui/popup-group-players/popup-group-players';
import 'firebase/compat/firestore';

class GroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			showGroupPlayersPopup: false,
			group: null,
			game: null,
		};
		this.unsubscribeGame = null;
		this.unsubscribeGroup = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Subscribe to group */
		this.subscribeToGroup().then((response) => {
			if (response.status === 'success') {
				
				/* Subscribe to game */
				this.subscribeToGame(response.gameId).then((response) => {
					if (response.status === 'success') {
						const playerNumber = (this.state.group.numberOfPlayers ? this.state.group.numberOfPlayers : 0);
						const showGroupPlayersPopup = (playerNumber === 0);
						this.setState({isLoading: false, showGroupPlayersPopup});
					}
				}).catch((error) => {
					console.error(error);
					this.setState({isLoading: false});
				});
			} else {
				this.setState({isLoading: false});
			}
		}).catch((error) => {
			console.error(error);
			this.setState({isLoading: false});
		});
	};

	/**
	 * Subscribe to group
	 * @returns {Promise}
	 */
	subscribeToGroup = () => {
		/* Cancel previous subscription */
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();
		
		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeGroup = db.collection(appConfig.groupsDbName).doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Subscribed succesfully */
					this.setState({group: {id: doc.id, ...doc.data()}}, () => {
						resolve({status: 'success', gameId: doc.data().gameId});		
					});
				} else {
					/* Error: could not subscribe to group */
					resolve({
						status: 'error', 
						error: 'Could not subscribe to group with id ' + this.props.userId + '.'
					});
				}
			});
		});
	};

	/**
	 * Subscribe to game
	 * @param {string} gameId 
	 * @returns 
	 */
	subscribeToGame = (gameId) => {
		/* Cancel previous subscription */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();

		return new Promise((resolve, reject) => {
			const db = firebase.firestore();
			this.unsubscribeGroup = db.collection(appConfig.gamesDbName).doc(gameId).onSnapshot((game) => {
				if (game.exists) {
					/* Subscribed succesfully */
					this.setState({game: game.data()}, () => {
						resolve({status: 'success'});
					});
				} else {
					resolve({
						status: 'error', 
						error: 'Could not subscribe to game with id ' + gameId + '.'
					});
				}
			});
		});
	};

	/**
	 * Toggle group players popup
	 */
	toggleGroupPlayersPopup = () => {
		const showGroupPlayersPopup = !this.state.showGroupPlayersPopup;
		this.setState({showGroupPlayersPopup});
	};
	
	/**
	 * Update group
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGroup = (updates) => {
		const groupId = this.props.userId;
		const db = firebase.firestore();
		const groupRef = db.collection(appConfig.groupsDbName).doc(groupId);
		return groupRef.update(updates);
	};

	/**
	 * Answer a question
	 * @param {string} questionId 
	 * @param {string} questionType 
	 * @param {string} optionId 
	 * @param {string} action // replace, add, subtract
	 * @param {any} value
	 * @returns 
	 */
	answerQuestion = (questionId, questionType, optionId, action, surveyId = null, optionsToSubtract = null) => {
		return new Promise((resolve) => {
			apiHelper('group/answer-question', {
				groupId: this.props.userId,
				questionId, questionType, optionId, action, surveyId, optionsToSubtract
			}).then((response) => {
				if (response.status === 'success') {
					resolve({status: 'success'});
				} else {
					resolve({status: 'error', error: response.error});
				}
			}).catch((error) => {
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from game */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();

		/* Unsubscribe from group */
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();
	};

	 /**
	 * Render component
	 */
	 render() {
		/* Loading */
		if (this.state.isLoading || !this.state.game || !this.state.group) {
			return (
				<Loading 
					loadErrMsg={this.state.loadErrMsg}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};

		/* Game page */
		return (
			<>
				<Game 
					deviceInfo={this.props.deviceInfo}
					gameData={this.state.game}
					groupData={this.state.group}
					toggleGroupPlayersPopup={this.toggleGroupPlayersPopup}
					updateGroup={this.updateGroup}
					answerQuestion={this.answerQuestion}
					handleLogout={this.props.handleLogout}
				/>
				{this.state.showGroupPlayersPopup && <PopupGroupPlayers 
					deviceInfo={this.props.deviceInfo}
					groupData={this.state.group}
					updateGroup={this.updateGroup}
					toggleGroupPlayersPopup={this.toggleGroupPlayersPopup}
				/>}
			</>
		);
	 }
}

GroupController.propTypes = {
	userId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default GroupController;
