import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import Background from 'components/background/background';
import Logo from '../logo/logo';
import Button from 'components/ui/button/button';
import './popup-group-players.scss';

const PopupGroupPlayers = ({deviceInfo, groupData, updateGroup, toggleGroupPlayersPopup}) => {

	const [isLoading, setIsLoading] = useState(false);
	const [errMsg, setErrMsg] = useState(null);

	/* Number of players */
	const [numberOfPlayers, setNumberOfPlayers] = 
		useState(groupData.numberOfPlayers ? groupData.numberOfPlayers : 1);
 
	/* Update number of players */
	const updateNumberOfPlayers = (action) => {
		setErrMsg(null);
		if (action === 'plus') {
			setNumberOfPlayers(numberOfPlayers + 1);
		} else if (numberOfPlayers > 0) {
			setNumberOfPlayers(numberOfPlayers - 1);
		}
	};

	/**
	 * Update group
	 */
	const saveNumberOfPlayers = () => {
		if (isLoading) return;

		setIsLoading(true);
		setErrMsg(null);
		updateGroup({numberOfPlayers: numberOfPlayers}).then(() => {
			toggleGroupPlayersPopup();
			setIsLoading(false);
		}).catch((error) => {
			console.error(error);
			setErrMsg(getText('error', 'unknownError'));
			setIsLoading(false);
		});

	};
	return (
		<div className={'PopupGroupPlayers ' + deviceInfo.orientation}>
			{/* Background */}
			<Background deviceInfo={deviceInfo}/>

			{/* Title */}
			<div className="PopupGroupPlayers-gameTitle">
				<span>{getText('generalTexts', 'gameTitleSplit')}</span>
			</div>

			{/* Logo */}
			<div className="PopupGroupPlayers-logo">
				<Logo page={deviceInfo.orientation + '-login'} />
			</div>

			{/* Content */}
			<div className='PopupGroupPlayers-content'>
				<div className='PopupGroupPlayers-title'>
					<span>{groupData.name}</span>
				</div>
				<div className='PopupGroupPlayers-image'/>
				<div className='PopupGroupPlayers-text'>
					<span>{getText('generalTexts', 'howManyPlayers')}</span>
				</div>
				<div className='PopupGroupPlayers-players'>
					<div className="PopupGroupPlayers-minusBtn" 
						onClick={() => {updateNumberOfPlayers('minus');}}
					/>
					<div className="PopupGroupPlayers-value"><span>{numberOfPlayers}</span></div>
					<div className="PopupGroupPlayers-plusBtn" 
						onClick={() => {updateNumberOfPlayers('plus');}}
					/>
				</div>

				<div className='PopupGroupPlayers-errMsg'>
					<span>{errMsg}</span>
				</div>
			</div>
			<div className='PopupGroupPlayers-button'>
				<Button
					isLoading={isLoading}
					isDisabled={(numberOfPlayers < 1)}
					classes={['pink', 'fullHeight']}
					text={getText('generalTexts', 'continue')}
					onClick={() => {saveNumberOfPlayers();}}
				/>
			</div>
		</div>
	);
};

PopupGroupPlayers.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	groupData: PropTypes.object.isRequired,
	updateGroup: PropTypes.func.isRequired,
	toggleGroupPlayersPopup: PropTypes.func.isRequired
};

export default PopupGroupPlayers;
