import React, { useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { getCurrentGameStepId, checkIfNextBtnIsDisplayed, checkIfConditionalNextStepId } from 'helpers/game-helper';
import { getText } from 'helpers/text-helper';
import { themesData } from 'data/themes-data';
import GameStepNav from 'components/ui/dev-tools/game-step-nav';
import GameStepVideo from 'components/game-steps/game-step-video/game-step-video';
import GameStepText from 'components/game-steps/game-step-text/game-step-text';
import GameStepQuestions from 'components/game-steps/game-step-questions/game-step-questions';
import DonePage from 'components/done-page/done-page';
import Button from 'components/ui/button/button';
import './game.scss';

const Game = (props) => {
	/* Props */
	const {
		deviceInfo, 
		gameData, 
		groupData, 
		toggleGroupPlayersPopup, 
		updateGroup, 
		answerQuestion, 
		handleLogout,
	} = props;

	/* Get theme data (default is first theme) */
	const themeData = (gameData.themeId 
		? themesData.find((t) => {return t.id === gameData.themeId;})
		: themesData[0]
	);

	/* Get current game step id */
	const [gameStepId, setGameStepId] = useState(getCurrentGameStepId(themeData, groupData));

	const [showEvalPopup, setShowEvalPopup] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Go to previous game step
	 */
	const goToPrevGameStep = () => {
		/* Get current game step index */
		const gameStepIndex = themeData.gameSteps.findIndex((step) => {return step.id === gameStepId;});

		if (themeData.gameSteps[gameStepIndex].type === 'questions' ) {
			// If question type, we want to go back through all players before going back to previous question
			let currentPlayerId = groupData.currentPlayerId ? groupData.currentPlayerId : 1;
			if (currentPlayerId > 1) {
				setShowEvalPopup(false);
				updateGroup({currentPlayerId: currentPlayerId - 1});
				return;
			}
		}

		if (gameStepIndex > 0 && gameStepIndex < themeData.gameSteps.length) {
			const prevGameStepId = themeData.gameSteps[gameStepIndex - 1].id;
			updateGroup({currentGameStepId: prevGameStepId, currentPlayerId: groupData.numberOfPlayers});
			setGameStepId(prevGameStepId);
		}
	};

	/**
	 * Test group answers against step questions, if any missing answers, trigger popup.
	 */
	const testForPopup = () => {
		const gameStep = themeData.gameSteps.find((step) => {return step.id === gameStepId;});

		if (gameStep) {
			const currentPlayerId = groupData.currentPlayerId ? groupData.currentPlayerId : 1;
			if (gameStep.maxAnswers) {
				const answers = groupData.questions.filter((q) => {
					return (
						q.surveyId === gameStep.id && 
						currentPlayerId === q.playerId
					);
				});
				if (answers.length < gameStep.maxAnswers.perPlayer) {
					setShowEvalPopup(true);
					return true;
				}
			} else {
				let hasMissingAnswer = false;
				gameStep.questions.every((question) => {
					const answer = groupData.questions.findIndex((q) => {
						return q.questionId === question.id && currentPlayerId === q.playerId;
					});

					// if a missing answer is found, break loop, show popup
					if (answer < 0) {
						setShowEvalPopup(true);
						hasMissingAnswer = true;
						return false;
					}
					return true;
				});
				return hasMissingAnswer;
			}
		}

		return false;
	};

	/**
	 * Go to next game step
	 */
	const goToNextGameStep = () => {
		/* Get current game step index */
		const gameStepIndex = themeData.gameSteps.findIndex((step) => {return step.id === gameStepId;});

		// If question type, we want to go through all players before continuing to next survey question
		if (
			themeData.gameSteps[gameStepIndex].type === 'questions' && 
			themeData.gameSteps[gameStepIndex].questions[0].type === 'evaluate'
		) {
			// Check for eval popup
			if (!showEvalPopup && testForPopup()) {
				return;
			}

			let currentPlayerId = groupData.currentPlayerId ? groupData.currentPlayerId : 1;
			if (currentPlayerId < groupData.numberOfPlayers) {
				setShowEvalPopup(false);
				updateGroup({currentPlayerId: currentPlayerId + 1});
				return;
			}
		}

		if (gameStepIndex + 1 < themeData.gameSteps.length) {
	
			/* Go to next game step */
			const nextGameStepId = checkIfConditionalNextStepId(gameStepData, groupData) 
				? themeData.gameSteps[gameStepIndex].nextStepId 
				: themeData.gameSteps[gameStepIndex + 1].id;

			setGameStepId(nextGameStepId);
			
			/* Update group progress */
			if (
				!groupData.currentGameStepId ||
				themeData.gameSteps.findIndex((step) => {
					return step.id === groupData.currentGameStepId;
				}) < (gameStepIndex + 1)
			) {
				updateGroup({currentGameStepId: nextGameStepId, currentPlayerId: null});
			}
		}
	};

	/**
	 * Handles toggleing between loading and not loading
	 */
	const toggleLoading = (bool) => {
		setIsLoading(bool);
	};

	/* Get current game step index */
	const gameStepIndex = themeData.gameSteps.findIndex((step) => {return step.id === gameStepId;});

	/* Get current game step data */
	const gameStepData = themeData.gameSteps[gameStepIndex];

	let gameSubType = null;

	/* Get current game step component */
	let GameStepComponent = GameStepText;
	if (gameStepData.type === 'questions') {
		GameStepComponent = GameStepQuestions;
		if (
			gameStepData.questions && 
			gameStepData.questions.length > 0 
		) {
			gameSubType = gameStepData.questions[0].type;
		}
	}
	if (gameStepData.type === 'video') GameStepComponent = GameStepVideo;
	if (gameStepData.type === 'finish') GameStepComponent = DonePage;

	/* Check if next btn is displayed */
	const showNextStepBtn = checkIfNextBtnIsDisplayed(gameStepData, groupData);

	return (
		<div className={'Game ' + deviceInfo.orientation}>
			{/* Group */}
			<div className="Game-group" onClick={() => {toggleGroupPlayersPopup();}}>
				<div className="Game-groupPlayers">
					<span>{(groupData.numberOfPlayers ? groupData.numberOfPlayers : 0)}</span>
				</div>
				<div className="Game-groupName"><span>{groupData.name}</span></div>
			</div>

			{/* Logout btn */}
			<div className='Game-logoutBtn'>
				<Button
					text={getText('generalTexts', 'logout')}
					classes={['pink']}
					onClick={() => {handleLogout();}}
				/>
			</div>

			{/* Game step page */}
			<div className={'Game-content ' + gameStepData.type + (gameSubType ? ' ' + gameSubType : '')}>
				<GameStepComponent
					toggleIsLoading={toggleLoading}
					deviceInfo={deviceInfo}
					gameStepData={gameStepData}
					groupData={groupData}
					answerQuestion={answerQuestion}
					goToNextGameStep={goToNextGameStep}
				/>
			</div>

			{/* Game footer */}
			<div className={'Game-footer ' + gameStepData.type}>
				{/* Button: previous step */}
				{gameStepData.prevBtn && <div className="Game-prevBtn">
					<Button 
						classes={['pink', 'fullHeight']}
						isLoading={isLoading}
						isDisabled={isLoading}
						text={gameStepData.prevBtn}
						onClick={() => {goToPrevGameStep();}}
					/>
				</div>}
				{/* Button: next step */}
				{showNextStepBtn && <div className="Game-nextBtn">
					<Button 
						classes={['pink', 'fullHeight']}
						isLoading={isLoading}
						isDisabled={isLoading}
						text={gameStepData.nextBtn}
						onClick={() => {goToNextGameStep();}}
					/>
				</div>}
			</div>

			{/* Dev tools: game step navigation */}
			{appConfig.showDevTools && <GameStepNav 
				gameStepId={gameStepId} 
				gameSteps={themeData.gameSteps} 
				setGameStepId={setGameStepId} 
			/>}

			{showEvalPopup && 
				<div className='Game-evalPopup'>
					<div className='Game-evalPopupWindow'>
						<div className='Game-evalPopupText'>
							<span>{getText('generalTexts', 'evalPopupMessage')}</span>
						</div>

						<div className='Game-evalPopupButtons'>
							<div className='Game-evalPopupButton'>
								<Button 
									classes={['pink', 'fullHeight']}
									text={getText('generalTexts', 'no')}
									onClick={() => {setShowEvalPopup(false);}}
								/>
							</div>
							<div className='Game-evalPopupButton'>
								<Button 
									classes={['pink', 'fullHeight']}
									text={getText('generalTexts', 'yes')}
									onClick={() => {setShowEvalPopup(false); goToNextGameStep();}}
								/>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
};

Game.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	groupData: PropTypes.object.isRequired,
	toggleGroupPlayersPopup: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	answerQuestion: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Game;