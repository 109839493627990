const processData = [
	{
		number: 1,
		text: 'Gå ud i grupper'
	},
	{
		number: 2,
		text: 'Log ind'
	},
	{
		number: 3,
		text: 'Se videoer og beslut forløb'
	},
	{
		number: 4,
		text: 'Svar på spørgsmål'
	},
	{
		number: 5,
		text: 'Fælles opsamling'
	},
];



export {
	processData
};