import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText, renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './popup-confirm-cookies.scss';

const PopupConfirmCookies = ({deviceInfo, toggleAcceptCookies, toggleCookiePopup}) => {

	/* Hide / show more info */
	const [showMoreInfo, setShowMoreInfo] = useState(false);
 
	return (
		<div className={'PopupConfirmCookies ' + deviceInfo.orientation}>
			<div className="PopupConfirmCookies-content">
				<div className="PopupConfirmCookies-closeBtn" onClick={() => {toggleCookiePopup(false);}}/>
				<div className="PopupConfirmCookies-text">
					{renderMarkdown(getText('cookieTexts', 'text1'))}
					{showMoreInfo && renderMarkdown(getText('cookieTexts', 'text2'))}
				</div>
				<div 
					className="PopupConfirmCookies-textBtn"
					onClick={() => {setShowMoreInfo(!showMoreInfo);}}
				>
					<span>
						{showMoreInfo ? getText('cookieTexts', 'seeLess') : getText('cookieTexts', 'seeMore')}
					</span>
				</div>
				<div className="PopupConfirmCookies-buttons">
					<div className="PopupConfirmCookies-button yes">
						<Button 
							text={getText('cookieTexts', 'yes')} 
							classes={['pink']} 
							onClick={() => {toggleAcceptCookies(true);}} 
						/>
					</div>
					<div className="PopupConfirmCookies-button no">
						<Button 
							text={getText('cookieTexts', 'no')} 
							classes={['pink']} 
							onClick={() => {toggleAcceptCookies(false);}} 
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PopupConfirmCookies.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	toggleAcceptCookies: PropTypes.func.isRequired,
	toggleCookiePopup: PropTypes.func.isRequired,
};

export default PopupConfirmCookies;
