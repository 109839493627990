import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import PopupMaxAnswers from 'components/ui/popup-max-answers/popup-max-answers';
import './evaluate.scss';

const Evaluate = (props) => {
	const {
		hasMultipleQuestions, 
		stepId,
		layout,
		deviceInfo, 
		question,
		evalPlayerAnswers,
		groupData, 
		gameStepData,
		handleAnswerQuestion,
		position,
		toggleIsLoading,
		isLoading
	} = props;

	/* Show max answer limit reached */
	const [hasHitAnswerLimit, setHasHitAnswerLimit] = useState(false);

	/* Group question data */
	const currentPlayerId = groupData.currentPlayerId ? groupData.currentPlayerId : 1;
	const groupQuestionData = (groupData.questions 
		? groupData.questions.find((q) => {
			return q.questionId === question.id && currentPlayerId === q.playerId;
		})
		: null
	);

	/**
	 * Get selected values for each option
	 * @param {object} groupQuestionData 
	 * @returns 
	 */
	const getOptionValues = (groupQuestionData) => {
		let optionValues = {};
		if (groupQuestionData && question.options) {
			question.options.forEach((option) => {
				if (groupQuestionData.hasOwnProperty(option.id)) {
					optionValues[option.id] = groupQuestionData[option.id];
				}
			});
		}
		return optionValues;
	};

	/* Selected option values */
	const [selectedOptionValues, setSelectedOptionValues] = useState(getOptionValues(groupQuestionData));

	/* Loading status */
	const [isUpdatingOptionId, setIsUpdatingOptionId] = useState(null);

	/**
	 * Update selected option id if it is updated on the server (i.e. by another group member)
	 */
	useEffect(() => {
		const newOptionValues = getOptionValues(groupQuestionData);
		question.options.forEach((option) => {
			if (
				newOptionValues.hasOwnProperty(option.id) &&
				( 
					!selectedOptionValues.hasOwnProperty(option.id) ||
					selectedOptionValues[option.id] !== newOptionValues[option.id]
				)
			) {
				setSelectedOptionValues(newOptionValues);
			}
		});
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupQuestionData]);

	useEffect(() => {
		const newCurrentPlayerId = groupData.currentPlayerId ? groupData.currentPlayerId : 1;
		const newGroupQuestionData = (groupData.questions 
			? groupData.questions.filter((q) => {
				return q.questionId === question.id && newCurrentPlayerId === q.playerId;
			})
			: null
		);

		setSelectedOptionValues(getOptionValues(newGroupQuestionData));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupData.currentPlayerId]);

	/**
	 * New game step id - update option values
	 */
	useEffect(() => {
		setSelectedOptionValues(getOptionValues(groupQuestionData));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepId]);

	/**
	 * Player clicked on an option
	 * @param {string} action 
	 * @param {string} optionId 
	 * @param {number} value 
	 * @returns 
	 */
	const handleOptionClick = (action, optionId, value) => {
		/* In the middle of updating or trying to set value below zero - return */
		if (isUpdatingOptionId || isLoading) return;

		/* Check max answers limit  */
		if (gameStepData.maxAnswers && action === 'plus') {
			if (evalPlayerAnswers.length >= gameStepData.maxAnswers.perPlayer) {
				setHasHitAnswerLimit(true);
				return;
			}
		}

		/* Update local state */
		setIsUpdatingOptionId(optionId);
		toggleIsLoading(true);
		const newSelectedOptionValues = JSON.parse(JSON.stringify(selectedOptionValues));
		const modifier = (action === 'minus' ? -1 : 1);

		const toSubtract = [];

		if (action === 'plus') {
			question.options.forEach((option) => {
				if (newSelectedOptionValues[option.id] > 0 && option.id !== optionId) {
					toSubtract.push(option.id);
					newSelectedOptionValues[option.id] = 0;
				}
			});
		}

		newSelectedOptionValues[optionId] = value + modifier;
		setSelectedOptionValues(newSelectedOptionValues);

		/* Update server */
		handleAnswerQuestion('eval', question.id, question.type, optionId, action, toSubtract).then(() => {
			setIsUpdatingOptionId(null);
			toggleIsLoading(false);
		});
	};

	return (
		<div className={'Evaluate ' + deviceInfo.orientation + (hasMultipleQuestions ? ' multiple' : '') 
			+ (layout ? ' ' + layout : '')
			+ ' ' + position
		}>
			{question.text && <div className="Evaluate-text">
				{renderMarkdown(question.text)}
			</div>}
			<div className={'Evaluate-options'}>
				{question.options.map((option, index)=> {
					const value = (selectedOptionValues && selectedOptionValues.hasOwnProperty(option.id)
						? selectedOptionValues[option.id]
						: 0
					);
					
					let hasDivider = false;
					if (question.options.length > 1 && index !== 0) {
						hasDivider = true;
					}

					let isTicked = value > 0;

					return (
						<div 
							key={option.id} 
							className={'Evaluate-option' + (isUpdatingOptionId === option.id ? ' loading' : '')
								+ (option.text ? ' text' : '')
								+ (hasDivider ? ' hasDivider' : '')
							}
						>
							{option.text && 
								<div className="Evaluate-optionText">
									<span>{option.text}</span>
								</div>
							}
							<div className={'Evaluate-optionValue ' + (isTicked ? 'isTicked' : '')}
								onClick={() => {handleOptionClick(isTicked ? 'minus' : 'plus', option.id, value);}}
							/>
						</div>
					);
				})}
			</div>
			{hasHitAnswerLimit && <PopupMaxAnswers 
				deviceInfo={deviceInfo}
				gameStepData={gameStepData}
				setHasHitAnswerLimit={setHasHitAnswerLimit}
			/>}
		</div>
	);
};

Evaluate.propTypes = {
	hasMultipleQuestions: PropTypes.bool.isRequired,
	stepId: PropTypes.string.isRequired,
	layout: PropTypes.string,
	deviceInfo: PropTypes.object.isRequired,
	question: PropTypes.object.isRequired,
	evalPlayerAnswers: PropTypes.array.isRequired,
	groupData: PropTypes.object.isRequired,
	gameStepData: PropTypes.object.isRequired,
	handleAnswerQuestion: PropTypes.func.isRequired,
	position: PropTypes.string.isRequired,
	toggleIsLoading: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default Evaluate;