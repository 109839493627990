import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import MultipleChoice from './multiple-choice/multiple-choice';
import Evaluate from './evaluate/evaluate';
import { getText } from 'helpers/text-helper';
import './game-step-questions.scss';

const GameStepQuestions = ({
	deviceInfo, 
	gameStepData, 
	groupData, 
	answerQuestion, 
	goToNextGameStep, 
	toggleIsLoading,
}) => {
	/* Loading status */
	const [isLoading, setIsLoading] = useState(false); 

	/* Get game step question(s) */
	const questions = gameStepData.questions ? gameStepData.questions : [];

	const [evalPlayerAnswers, setEvalPlayerAnswers] = useState([]);

	// Use effect to handle player answers for evaluation whenever a new playerid is set
	useEffect(() => {
		const gameStepElement = document.getElementById('top');
		if (gameStepElement) {
			gameStepElement.scrollIntoView();
		}

		if (gameStepData.questions[0].type === 'evaluate') {
			const currentPlayerId = groupData.currentPlayerId ? groupData.currentPlayerId : 1;
			const playerAnswers = (groupData && groupData.questions
				? groupData.questions.filter((question) => {
					return (
						question.surveyId === groupData.currentGameStepId && 
						question.playerId === currentPlayerId
					);
				})
				: []
			);

			setEvalPlayerAnswers(playerAnswers);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupData.currentPlayerId]);

	// Use effect to handle whenever player answers for evaluation whenever a new step id is set
	useEffect(() => {
		if (gameStepData.questions[0].type === 'evaluate') {
			const currentPlayerId = groupData.currentPlayerId ? groupData.currentPlayerId : 1;
			const playerAnswers = (groupData && groupData.questions
				? groupData.questions.filter((question) => {
					return (
						question.surveyId === groupData.currentGameStepId && 
						question.playerId === currentPlayerId
					);
				})
				: []
			);

			setEvalPlayerAnswers(playerAnswers);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gameStepData.currentGameStepId]);

	/**
	 * Answer a questions
	 * Either select a mc option or adjust a rating
	 * @param {string} questionId 
	 * @param {string} questionType 
	 * @param {string} optionId 
	 * @param {string} action 
	 * @param {string} answer 
	 * @param {any} value 
	 * @returns 
	 */
	const handleAnswerQuestion = (
		type, 
		questionId, 
		questionType, 
		optionId, 
		action = null,
		optionsToSubtract = null
	) => {
		const currentPlayerId = groupData.currentPlayerId ? groupData.currentPlayerId : 1;

		const newEvalAnswers = evalPlayerAnswers;
		if (action === 'plus') {
			// Add option to evalPlayerAnswers
			const newEvalObject = {
				playerId: currentPlayerId,
				questionId: questionId,
				surveyId: gameStepData.id
			};
			newEvalObject[optionId] = 1;
			newEvalAnswers.push(newEvalObject);
			setEvalPlayerAnswers(newEvalAnswers);
		} else {
			// Remove option from evalPlayerAnswers
			const existingObjectIndex = newEvalAnswers.findIndex((answer) => {
				return answer.questionId === questionId && answer.playerId === currentPlayerId;
			});
			if (existingObjectIndex > -1) {
				newEvalAnswers.splice(existingObjectIndex, 1);
				setEvalPlayerAnswers(newEvalAnswers);
			}
		}

		return new Promise((resolve) => {
			if (isLoading) {
				resolve({status: 'error', error: 'loading'});
			} else {
				setIsLoading(true);
				answerQuestion(questionId, questionType, optionId, action, gameStepData.id, optionsToSubtract)
					.then(() => {
						setIsLoading(false);
						if (type === 'mc') {
							goToNextGameStep();
						}
						resolve({status: 'success'});
					});
			}
		});
	};

	return (
		<div id='questions' className={'GameStepQuestions ' + deviceInfo.orientation}>
			<div id='top'/> {/* Lazy solution to get scroll to top to work */}
			{questions[0].type === 'evaluate' &&
				<div className='GameStepQuestions-currentMember'>
					<span>
						{
							getText('generalTexts', 'groupMember') + ' ' +
							(groupData.currentPlayerId ? groupData.currentPlayerId : 1) + 
							'/' + groupData.numberOfPlayers
						}
					</span>
				</div>
			}
			{gameStepData.text && 
				<div className="GameStepQuestions-title"><span>{gameStepData.text}</span></div>}
			{gameStepData.subText && 
				<div className="GameStepQuestions-subText"><span>{gameStepData.subText}</span></div>}
			<div className={'GameStepQuestions-questions' + (questions.length > 1 ? ' multiple' : '')}>
				{questions.map((question, index) => {
					/* Question component depends on type of question */
					let QuestionComponent = MultipleChoice;
					let keyAddition = '';
					if (question.type === 'evaluate') {
						QuestionComponent = Evaluate;
						keyAddition += groupData.currentPlayerId ? groupData.currentPlayerId : 1;
					}
					
					return (
						<div key={index + keyAddition} className={'GameStepQuestions-question ' + question.type}>
							<QuestionComponent 
								hasMultipleQuestions={(questions.length > 1)}
								deviceInfo={deviceInfo}
								layout={(gameStepData.layout ? gameStepData.layout : null)}
								stepId={gameStepData.id}
								gameStepData={gameStepData}
								question={question}
								evalPlayerAnswers={evalPlayerAnswers}
								groupData={groupData}
								handleAnswerQuestion={handleAnswerQuestion}
								position={'position-' + index}
								toggleIsLoading={toggleIsLoading}
								isLoading={isLoading}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

GameStepQuestions.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameStepData: PropTypes.object.isRequired,
	groupData: PropTypes.object.isRequired,
	answerQuestion: PropTypes.func.isRequired,
	goToNextGameStep: PropTypes.func.isRequired,
	toggleIsLoading: PropTypes.func.isRequired,
};

export default GameStepQuestions;