import React from 'react';
import PropTypes from 'prop-types';
import { getText } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './loading.scss';

const Loading = ({loadErrMsg, handleLogout, alternativeText = null}) => {
	const loadingText = (loadErrMsg ? loadErrMsg : getText('generalTexts', 'loading'));

	return (
		<div className="Loading">
			<div className="Loading-panel" >
				<div className="Loading-logoutBtn" > 
					<Button
						text={getText('generalTexts', 'logout')}
						classes={['pink']}
						onClick={() => {handleLogout();}}
					/>
				</div>
				<div className="Loading-content">
					<div className="Loading-circles">
						<div className="Loading-circle" />
						<div className="Loading-circle" />
						<div className="Loading-circle" />
					</div>
					<div className="Loading-text">{alternativeText === null ? loadingText : alternativeText}</div>
				</div>
			</div>
		</div>
	);
};

Loading.propTypes = {
	loadErrMsg: PropTypes.string,
	handleLogout: PropTypes.func.isRequired,
	alternativeText: PropTypes.string,
};

export default Loading;
