import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import './done-page.scss';

const DonePage = ({deviceInfo, gameStepData}) => {
	
	return (
		<div className={'DonePage ' + deviceInfo.orientation}>
			<div className='DonePage-content'>
				<div className='DonePage-title'>
					<span>{getText('generalTexts', 'donePageTitle')}</span>
				</div>
				<div className={'DonePage-image ' + gameStepData.id}/>
				<div className='DonePage-text'>
					<span>{getText('generalTexts', 'donePageText')}</span>
				</div>
			</div>
			<div className='DonePage-gameTitle'>
				<span>{getText('generalTexts', 'gameTitleSplit')}</span>
			</div>
		</div>
	);
};

DonePage.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameStepData: PropTypes.object.isRequired,
};

export default DonePage;