import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import Logo from 'components/ui/logo/logo';
import LoginFacilitator from './user-login/login-facilitator';
import ResetPassword from './reset-password/reset-password';
import CreateUser from './create-user/create-user';
import LoginGroup from './user-login/login-group';
import CompanyLogos from 'components/ui/company-logos/company-logos';
import './login.scss';

const Login = (props) => {
	/* Props */
	const {
		cookiesAccepted, 
		deviceInfo, 
		toggleCookiePopup, 
		facilitatorLogin, 
		findGame, 
		createGroup, 
		getLoginToken, 
		loginWithToken,
	} = props;

	/* Facilitator action (login, create-user or reset-password) */
	const [facilitatorAction, setFacilitatorAction] = useState('login');

	/* Login component */
	const loginRole = (window.location.pathname.toLowerCase().includes('spiller') ? 'player' : 'facilitator');
	let LoginComponent = LoginGroup;
	if (loginRole === 'facilitator') {
		LoginComponent = LoginFacilitator;
	}
	if (facilitatorAction === 'create-user') LoginComponent = CreateUser;
	if (facilitatorAction === 'reset-password') LoginComponent = ResetPassword;

	return (
		<div className={'Login ' + deviceInfo.orientation + ' ' + loginRole}>
			{/* Title */}
			<div className="Login-title">
				<span>{getText('generalTexts', 'gameTitleSplit')}</span>
			</div>

			{/* Login component */}
			<div className={'Login-wrap ' + loginRole}>
				<LoginComponent 
					cookiesAccepted={cookiesAccepted}
					toggleCookiePopup={toggleCookiePopup}
					facilitatorLogin={facilitatorLogin}
					setFacilitatorAction={setFacilitatorAction}
					findGame={findGame}
					createGroup={createGroup}
					getLoginToken={getLoginToken}
					loginWithToken={loginWithToken}
				/>
			</div>
			
			{/* Logo */}
			<div className="Login-logo">
				<Logo page={deviceInfo.orientation + '-login'} />
			</div>

			{/* Company logos */}
			<CompanyLogos deviceInfo={deviceInfo} />
		</div>
	);
};

Login.propTypes = {
	cookiesAccepted: PropTypes.bool.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	toggleCookiePopup: PropTypes.func.isRequired,
	facilitatorLogin: PropTypes.func.isRequired,
	findGame: PropTypes.func.isRequired,
	createGroup: PropTypes.func.isRequired,
	getLoginToken: PropTypes.func.isRequired,
	loginWithToken: PropTypes.func.isRequired,
};

export default Login;