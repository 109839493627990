import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import CompanyLogos from 'components/ui/company-logos/company-logos';
import './settings.scss';

const Settings = ({deviceInfo, gameData, goToPage, handleLogout}) => {


	const gameStartText = (gameData
		? getText('pageTexts', 'gameStartDescription')
		: getText('pageTexts', 'gameStartWelcome')
	);
	return (
		<div className="Settings">
			{/* Title */}
			<div className="Settings-title">
				<span>{getText('generalTexts', 'gameTitleSplit')}</span>
			</div>

			{/* Settings page */}
			<div className={'Settings-content' + (gameData ? '' : ' newUser')}>
				<div className='Settings-text'><span>{gameStartText}</span></div>
				<div className='Settings-buttons'>
					<div className='Settings-button'>
						<Button
							classes={['pink', 'fullHeight']}
							text={getText('generalTexts', 'newGame')}
							onClick={() => {goToPage('create-game');}}
						/>
					</div>
					{gameData && <div className='Settings-button'>
						<Button
							classes={['pink', 'fullHeight']}
							text={getText('generalTexts', 'continueGame')}
							onClick={() => {goToPage('game-info');}}
						/>
					</div>}
				</div>
			</div>

			{/* Logo */}
			<div className="Settings-logo">
				<Logo page={deviceInfo.orientation + '-login'} />
			</div>

			<CompanyLogos deviceInfo={deviceInfo} />

			{/* Logout btn */}
			<div className='Settings-logoutBtn'>
				<Button
					text={getText('generalTexts', 'logout')}
					classes={['pink']}
					onClick={() => {handleLogout();}}
				/>
			</div>
		</div>
	);
};

Settings.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object,
	goToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Settings;