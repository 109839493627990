import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import './game-step-text.scss';

const GameStepText = ({deviceInfo, gameStepData}) => {
	
	return (
		<div className={'GameStepText ' + deviceInfo.orientation}>
			{gameStepData.title && <div className="GameStepText-title"><span>{gameStepData.title}</span></div>}
			<div className="GameStepText-text">
				{renderMarkdown(gameStepData.text)}
			</div>
		</div>
	);
};

GameStepText.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameStepData: PropTypes.object.isRequired
};

export default GameStepText;