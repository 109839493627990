import React, { useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './login-group.scss';

const LoginGroup = ({cookiesAccepted, toggleCookiePopup, findGame, createGroup, getLoginToken, loginWithToken}) => {
	/* Loading status and error message */
	const [isLoading, setIsLoading] = useState(false);
	const [errMsg, setErrMsg] = useState(null);
	
	/* Game code */
	const [gameCode, setGameCode] = useState(null);

	/* Game groups */
	const [gameGroups, setGameGroups] = useState(null);
	const [selectedGroupId, setSelectedGroupId] = useState(null);

	/* Show create group, group name input */
	const [showCreateGroup, setShowCreateGroup] = useState(false);
	const [groupName, setGroupName] = useState(null);
	
	/**
	 * Handle game code input
	 * @param {object} event 
	 */
	const handleGameCodeInput = (event) => {
		setErrMsg(null);
		setGameCode(event.target.value);
	};

	/**
	 * Find game with inputed game code
	 */
	const handleFindGame = (event) => {
		if (event) event.preventDefault();

		if (!cookiesAccepted) {
			toggleCookiePopup(true);
		} else if (!gameCode || gameCode.length === 0) {
			/* Error: no game code entered */
			setErrMsg(getText('errorTexts', 'missingFields'));
		} else {
			/* Finding game */
			setIsLoading(true);
			findGame(gameCode.toLowerCase()).then((response) => {
				/* Game found, show game groups */
				if (response.status === 'success') {
					setIsLoading(false);
					setGameGroups(response.groups);
					if (response.groups.length === 0) {
						/* No groups yet, automatically show 'create group' page */
						setShowCreateGroup(true);
					}
				} else {
					/* Game not found */
					let newErrMsg = getText('errorTexts', 'unknownError');
					if (response.error === 'gameDoesNotExist') {
						newErrMsg = getText('errorTexts', 'gameDoesNotExist');
					}
					setErrMsg(newErrMsg);
					setIsLoading(false);
				}
			});
		}
	};

	/**
	 * Get login token for existing group and log in
	 * @param {string} groupId 
	 */
	const handleGetLoginToken = (groupId) => {
		setIsLoading(true);
		setSelectedGroupId(groupId);
		getLoginToken(gameCode.toLowerCase(), groupId).then((response) => {
			if (response.status === 'success') {
				loginWithToken(response.token).catch((error) => {
					console.error(error);
					setErrMsg(getText('errorTexts', 'unknownError'));
					setShowCreateGroup(false);
					setIsLoading(false);
					setSelectedGroupId(null);
				});
			} else {
				console.error(response.error);
				setErrMsg(getText('errorTexts', 'unknownError'));
				setShowCreateGroup(false);
				setIsLoading(false);
				setSelectedGroupId(null);
			}
		});
	};

	/**
	 * Handle group name input
	 * @param {object} event 
	 */
	const handleGroupNameInput = (event) => {
		setErrMsg(null);
		setGroupName(event.target.value);
	};

	/**
	 * Create new group and log in
	 */
	const handleCreateGroup = (event) => {
		if (event) event.preventDefault();

		if (isLoading) return;
		
		if (!groupName || groupName.length < appConfig.groupNameMinLength) {
			/* Group name too short */
			const newErrMsg = JSON.parse(JSON.stringify(getText('errorTexts', 'groupNameTooShort')))
				.replace('%minChars%', appConfig.groupNameMinLength);
			setErrMsg(newErrMsg);
		} else if (groupName.length > appConfig.groupNameMaxLength) {
			/* Group name too long */
			const newErrMsg = JSON.parse(JSON.stringify(getText('errorTexts', 'groupNameTooLong')))
				.replace('%maxChars%', appConfig.groupNameMaxLength);
			setErrMsg(newErrMsg);
		} else {
			setIsLoading(true);
			createGroup(gameCode.toLowerCase(), groupName).then((response) => {
				if (response.status === 'success') {
					loginWithToken(response.token).catch((error) => {
						console.error(error);
						setErrMsg(getText('errorTexts', 'unknownError'));
						setShowCreateGroup(false);
						setIsLoading(false);
					});
				} else {
					let newErrMsg = getText('errorTexts', 'unknownError');
					if (response.error === 'group-name-already-exists') {
						newErrMsg = getText('errorTexts', 'groupAlreadyExists');
					}
					setErrMsg(newErrMsg);
					setIsLoading(false);
				}
			});
		}
	};

	/* Create new group */
	if (showCreateGroup || (gameGroups !== null && gameGroups.length === 0)) {
		return (
			<div className='LoginGroup create'>
				<form className='LoginGroup-form' onSubmit={handleCreateGroup}>
					<div className='LoginGroup-title'>
						<span>{getText('generalTexts', 'newGroup')}</span>
					</div>
					<div className='LoginGroup-image'/>
					<input
						className='LoginGroup-input name'
						name="groupName"
						type="text"
						placeholder={getText('generalTexts', 'writeGroupName')} 
						autoComplete="section-grouplogin groupname"
						value={groupName ? groupName : ''} 
						onChange={(event)=>{handleGroupNameInput(event);}}
					/>
					<div className='LoginGroup-button'>
						<Button
							isLoading={isLoading}
							type="submit"
							text={getText('generalTexts', 'createGroup')}
							classes={['pink', 'fullHeight']}
						/>
					</div>
					<div className='LoginGroup-errMsg'>
						<span>{errMsg}</span>
					</div>
				</form>
			</div>
		);
	}
	
	/* Select group to log in as */
	if (gameGroups !== null) {
		return (
			<div className='LoginGroup groups'>
				<div className='LoginGroup-form'>
					<div className='LoginGroup-title'>
						<span>{getText('generalTexts', 'selectAGroup')}</span>
					</div>
					<div className='LoginGroup-newGroupBtn' onClick={() => {setShowCreateGroup(true);}}>
						<span>{getText('generalTexts', 'createGroup')}</span>
					</div>
					<div className='LoginGroup-groups'>
						{gameGroups.map((group) => {
							return (
								<div 
									key={group.id}
									onClick={() => {handleGetLoginToken(group.id);}} 
									className={'LoginGroup-group' 
										+ (isLoading && selectedGroupId === group.id ? ' loading' : '')}
								>
									<span>{group.name}</span>
								</div>
							);
						})}
					</div>
					<div className='LoginGroup-errMsg'>
						<span>{errMsg}</span>
					</div>
				</div>
			</div>
		);
	}

	/* Enter game code to find game */
	return (
		<div className='LoginGroup login'>
			<form className='LoginGroup-form' onSubmit={handleFindGame}>
				<div className='LoginGroup-label'>
					<span>{getText('generalTexts', 'gameCode')}</span>
				</div>
				<input
					className='LoginGroup-input'
					name="gameCode"
					type="text"
					placeholder={''} 
					autoComplete="section-grouplogin gamecode"
					value={gameCode ? gameCode : ''} 
					onChange={(event)=>{handleGameCodeInput(event);}}
				/>
				<div className='LoginGroup-errMsg'>
					<span>{errMsg}</span>
				</div>
				<div className='LoginGroup-button'>
					<Button
						type="submit"
						text={getText('generalTexts', 'login')}
						classes={['pink', 'fullHeight']}
					/>
				</div>

			</form>
		</div>
	);
};

LoginGroup.propTypes = {
	cookiesAccepted: PropTypes.bool.isRequired,
	toggleCookiePopup: PropTypes.func.isRequired,
	findGame: PropTypes.func.isRequired,
	createGroup: PropTypes.func.isRequired,
	getLoginToken: PropTypes.func.isRequired,
	loginWithToken: PropTypes.func.isRequired,
};

export default LoginGroup;