import React, { useState } from 'react';
import PropTypes from 'prop-types';
import apiHelper from 'helpers/api-helper';
import { getText } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './create-user.scss';

const CreateUser = ({setFacilitatorAction}) => {
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [passwordRepeat, setPasswordRepeat] = useState(null);
	const [feedback, setFeedback] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Handles email input
	 * @param {object} event 
	 */
	const handleEmailInput = (event) => {
		setFeedback(null);
		setEmail(event.target.value);
	};

	/**
	 * Handles password input
	 * @param {object} event 
	 */
	const handlePasswordInput = (event) => {
		setFeedback(null);
		setPassword(event.target.value);
	};

	/**
	 * Handles password repeat input
	 * @param {object} event 
	 */
	const handlePasswordRepeatInput = (event) => {
		setFeedback(null);
		setPasswordRepeat(event.target.value);
	};

	/**
	 * Checks if both passwords are the same, if so attempts to create new user.
	 */
	const handleCreateUser = (event) => {
		if (event) event.preventDefault();

		// Check if both passwords are the same
		if (password !== passwordRepeat) {
			setFeedback(getText('errorTexts', 'differentPasswords'));
			return;
		}

		setIsLoading(true);
		
		return new Promise((resolve) => {
			apiHelper('facilitator/create-facilitator', {
				email: email,
				password: password,
			}).then((data) => {
				setIsLoading(false);

				if (data.status === 'success') {
					setFacilitatorAction('login');
					resolve({status: 'success'});
				} else {
					setFeedback(data.error.message);
					resolve({status: 'error', error: data.error.message});
				}
			}).catch((error) => {
				setIsLoading(false);
				setFeedback(error.error);
				resolve({status: 'error', error: error.error});
			});
		});
	};

	return (
		<div className='CreateUser'>
			<form className="CreateUser-form" onSubmit={handleCreateUser}>
				<div className='CreateUser-label'>
					<span>{getText('generalTexts', 'email')}</span>
				</div>
				<input
					name="E-mail"
					type="text"
					autoComplete="section-createUser email"
					value={email ? email : ''}
					onChange={(event)=>{handleEmailInput(event);}}
				/>

				<div className='CreateUser-label'>
					<span>{getText('generalTexts', 'password')}</span>
				</div>
				<input
					name="password"
					type="password"
					autoComplete="section-createUser password"
					value={password ? password : ''}
					onChange={(event)=>{handlePasswordInput(event);}}
				/>
					
				<div className='CreateUser-label'>
					<span>{getText('generalTexts', 'repeatPassword')}</span>
				</div>
				<input
					name="passwordRepeat"
					type="password"
					autoComplete="section-createUser repeatpassword"
					value={passwordRepeat ? passwordRepeat : ''}
					onChange={(event)=>{handlePasswordRepeatInput(event);}}
				/>

				<div className='CreateUser-button'>
					<Button
						type="submit"
						isLoading={isLoading}
						isDisabled={isLoading}
						classes={['pink', 'fullHeight']}
						text={getText('generalTexts', 'createUser')}
					/>
				</div>

				<p className="CreateUser-errorMessage">{feedback}</p>
					
				<div 
					className="CreateUser-backButton"
					onClick={()=>{setFacilitatorAction('login');}}
				>
					<span>{getText('generalTexts', 'backToLogin')}</span>
				</div>
			</form>
		</div>
	);
};

CreateUser.propTypes = {
	setFacilitatorAction: PropTypes.func.isRequired,
};

export default CreateUser;