import React from 'react';
import PropTypes from 'prop-types';
import {getText, renderMarkdown} from 'helpers/text-helper';
import {processData} from 'data/process-data';
import Button from 'components/ui/button/button';
import './game-info.scss';

const GameInfo = ({goToPage}) => {

	return (
		<div className='GameInfo'>
			{/* Game title */}
			<div className='GameInfo-gameTitle' onClick={() => {goToPage('settings');}}>
				<span>{getText('generalTexts', 'gameTitleSplit')}</span>
			</div>

			{/* Content */}
			<div className='GameInfo-content'>
				<div className='GameInfo-title'>
					{renderMarkdown(getText('generalTexts', 'welcome'))}
				</div>
				<div className='GameInfo-process'>
					{processData.map((step) => {
						return (
							<div key={step.number} className="GameInfo-processStep">
								<span>{step.number}</span>
								<span>{step.text}</span>
							</div>
						);
					})}
				</div>
				<div className='GameInfo-text'>
					{renderMarkdown(getText('generalTexts', 'instructions'))}
				</div>

			</div>


			{/* Next btn */}
			<div className='GameInfo-nextBtn'>
				<Button
					text={getText('generalTexts', 'continue')}
					classes={['pink', 'fullHeight']}
					onClick={() => {goToPage('game-quote');}}
				/>
			</div>

		</div>
	);
};

GameInfo.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default GameInfo;