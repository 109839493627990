

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import {getGameResults} from 'helpers/game-helper';
import Result from 'components/ui/results/result';
import Button from 'components/ui/button/button';
import './game-results.scss';

const GameResults = ({gameData, groupsData, goToPage}) => {
	/* Get results */
	const resultsData = getGameResults(gameData, groupsData);
	
	/* Result page index */
	const [resultId, setResultId] = useState(resultsData.length > 0 ? resultsData[0].id : null);

	/**
	 * Go to prev result page / game status page
	 */
	const handleGoToResult = (direction) => {
		const resultIndex = resultsData.findIndex((r) => {return r.id === resultId;});
		if (resultIndex < 0) return;

		if (direction === 'prev') {
			if (resultIndex === 0) {
				goToPage('game-status');
			} else {
				setResultId(resultsData[resultIndex - 1].id);
			}
		} else if (direction === 'next') {
			if (resultIndex + 1 < resultsData.length) {
				setResultId(resultsData[resultIndex + 1].id);
			} else {
				goToPage('game-end');
			}
		}
	};

	/* Get data for current result */
	const currentResultData = resultsData.find((r) => {return r.id === resultId;});

	return (
		<div className='GameResults'>
			<div className='GameResults-title' onClick={() => {goToPage('settings');}}>
				<span>{getText('generalTexts', 'gameTitle')}</span>
			</div>
			<div className={'GameResults-content'}>
				<Result resultId={resultId} gameData={gameData} groupsData={groupsData} />
			</div>


			<div className={'GameResults-prevBtn ' + currentResultData.type
				+ (currentResultData.questions ? ' questions-' + currentResultData.questions.length : '')}
			>
				<Button 
					classes={['pink', 'fullHeight']}
					text={getText('generalTexts', 'back')}
					onClick={() => {handleGoToResult('prev');}}
				/>
			</div>
			<div className={'GameResults-nextBtn ' + currentResultData.type
				+ (currentResultData.questions ? ' questions-' + currentResultData.questions.length : '')}
			>
				<Button 
					classes={['pink', 'fullHeight']}
					text={getText('generalTexts', 'continue')}
					onClick={() => {handleGoToResult('next');}}
				/>
			</div>
		
		</div>
	);
};

GameResults.propTypes = {
	gameData: PropTypes.object.isRequired,
	groupsData: PropTypes.array.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default GameResults;