import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './game-step-nav.scss';

const GameStepNav = ({gameStepId, gameSteps, setGameStepId}) => {
	
	/* Track if open / closed */
	const [isOpen, setIsOpen] = useState(false);

	/* Get current game step index */
	const selectedGameStepId = gameSteps.findIndex((step) => {return step.id === gameStepId;});

	/* On open, autoscroll to current task on list */
	useEffect(() => {
		if (isOpen) {
			const stepElements = document.getElementsByClassName('GameStepNav-item selected');
			if (stepElements) {
				stepElements[0].scrollIntoView(true);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<div className={'GameStepNav' + (isOpen ? ' open' : '')} onClick={() => {setIsOpen(!isOpen);}}>
			<div className="GameStepNav-toggleBtn" />
			{isOpen && <div className="GameStepNav-items">
				{gameSteps.map((step, index) => {
					return (
						<div 
							key={step.id} 
							className={'GameStepNav-item' + 
								(index < selectedGameStepId ? ' prev' : '') +
								(step.id === gameStepId ? ' selected' : '')
							}
							onClick={() => {setGameStepId(step.id);}}
						>{step.id} <span>({step.type + (step.subtype ? ' ' + step.subtype : '')})</span></div>
					);
				})}
			</div>}
		</div>
	);
};

GameStepNav.propTypes = {
	gameStepId: PropTypes.string.isRequired,
	gameSteps: PropTypes.array.isRequired,
	setGameStepId: PropTypes.func.isRequired,
};

export default GameStepNav;
