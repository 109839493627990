import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getVideoId} from 'helpers/game-helper';
import YouTube from 'react-youtube';
import './game-step-video.scss';
import { renderMarkdown } from 'helpers/text-helper';

const GameStepVideo = ({deviceInfo, gameStepData, groupData}) => {
	/* Video state and ref */
	const [videoState, setVideoState] = useState('loading');
	const [videoRef, setVideoRef] = useState(null);

	/**
	 * Start video
	 * @param {object} e 
	 * @returns 
	 */
	const handlePlay = (e) => {
		e.stopPropagation();
		if (videoState === 'loading') return;
		if (videoRef && videoRef.playVideo) {
			setVideoState('buffering');
			videoRef.playVideo();
		}
	};

	/**
	 * Video ended
	 */
	const videoEnded = () => {
		setVideoState('ended');

		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	};

	const videoId = getVideoId(gameStepData, groupData);


	return (
		<div className={'GameStepVideo ' + deviceInfo.orientation}>
			<div className="GameStepVideo-title"><span>{gameStepData.title}</span></div>
			{gameStepData.text && <div className="GameStepVideo-text">{renderMarkdown(gameStepData.text)}</div>}
			{videoId && 
				<div className="GameStepVideo-videoWrap">
					<YouTube 
						className={'GameStepVideo-video ' + videoState} 
						videoId={videoId} 
						onReady={(e) => {setVideoRef(e.target); setVideoState('ready');}}
						onPlay={() => {setVideoState('playing');}}
						onEnd={() => {videoEnded();}}
						opts={{
							height: '100%', 
							width: '100%', 
							playerVars: {
								controls: 1,
							}
						}}
					/>
					<div 
						className={'GameStepVideo-videoPlayBtn ' + videoState} 
						onClick={(e) => {handlePlay(e);}} 
					/>
				</div>
			}
		</div>
	);
};

GameStepVideo.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameStepData: PropTypes.object.isRequired,
	groupData: PropTypes.object.isRequired,
};

export default GameStepVideo;