const themesData = [
	{
		id: 'theme-1',
		title: 'Arrangementer for alle',
		titleShort: 'Sociale arrangementer',
		subtitle: 'Arrangementer uden for skoletiden kan styrke fællesskabet. Men de kan også være ekskluderende, hvis alle ikke har mulighed for at deltage.',
		description: `1. Arrangementer for alle
			<br /><br />Arrangementer uden for skoletiden kan styrke fællesskabet. Men de kan også være ekskluderende, hvis alle ikke har mulighed for at deltage.`,
		quote: `”Arrangementer laves af dem med overskud, men de glemmer, at det skal være sjovt at være med, selvom man er en familie med underskud af fx tid, søvn, penge, osv.”`,
		gameSteps: require('data/game-steps/theme-1-game-steps').gameSteps,
		results: [
			{
				id: 'result-1',
				type: 'sum-all',
				gameStepId: 't1-survey1',
				text: 'Hvad er vigtigt at tænke på, når I planlægger sociale arrangementer?'
			},
			{
				id: 'result-2',
				type: 'sum-each',
				gameStepId: 't1-survey2',
				text: 'Hvilke sociale arrangementer skal vi have i vores klasse?'
			}
		],
		furtherDiscussion: `<ul>
			<li>Hvordan afholdes arrangementer og hvor mange skal vi have (fx maks to sociale arrangementer årligt)?</li>
			<li>Hvordan afholder vi børnefødselsdage?</li> 
			<li>Hvordan skal vi forholde os til fødselsdagsgaver?</li></ul>`
	},
	{
		id: 'theme-2',
		titleShort: 'Venskaber',
		title: 'Legegrupper og legeaftaler',
		subtitle: 'Leg giver mulighed for at danne afgørende relationer. Men nogle børn skal hjælpes til at indgå i legen.',
		description: `2. Legegrupper og legeaftaler
			<br /><br />”Det vigtigste er at have en ven”, giver børnene udtryk for. At mødes i leg og danne relationer med hinanden er helt afgørende for børnene. Men nogle børn skal hjælpes til at indgå i legen og fællesskabet.`,
		quote: `“Det er ikke så nemt for mig at møde nye venner, og det er svært at prøve noget for første gang. Derfor kan jeg godt lide, når de voksne hjælper.”`,
		gameSteps: require('data/game-steps/theme-2-game-steps').gameSteps,
		results: [
			{
				id: 'result-1',
				type: 'sum-all',
				gameStepId: 't2-survey1',
				text: 'Hvad er vigtigt at tænke på, når der planlægges legegrupper?'
			},
			{
				id: 'result-2',
				type: 'sum-each',
				gameStepId: 't2-survey2',
				text: 'Hvad er vigtigt at tænke på, når vi som forældre skal styrke fællesskabet i klassen?'
			}
		],
		furtherDiscussion: `<ul>
			<li>Vil vi have legegrupper? Og hvordan sikrer vi i givet fald, at alle kan og vil deltage?</li>
			<li>Hvad gør vi, hvis et barn er udfordret i fællesskabet?</li> 
			<li>Hvordan sikrer vi, at alle børn leger på tværs?</li></ul> `
	},
	{
		id: 'theme-3',
		title: 'Konflikter',
		titleShort: 'Konflikter',
		subtitle: 'Når børnene oplever konflikter søger de hjælp hos forældrene. Hvad forældrene så gør kan både op- og nedtrappe konflikten.',
		description: `3. Hvordan håndterer vi konflikter
			<br /><br />Børnene vil opleve konfliktfyldte situationer, og måske søge hjælp hos forældrene til at løse dem. Forældres handlinger kan påvirke konflikten på forskellige måder – både optrappende og nedtrappende. `,
		quote: `”Jeg synes selv, at det handlede om dynamikken i gruppen, men jeg oplevede, at andre gerne ville placere skylden hos min datter.”`,
		gameSteps: require('data/game-steps/theme-3-game-steps').gameSteps,
		results: [
			{
				id: 'result-1',
				type: 'sum-all',
				gameStepId: 't3-survey1',
				text: 'Hvad er vigtigt, når der opstår konflikter i forældregruppen?'
			},
			{
				id: 'result-2',
				type: 'sum-each',
				gameStepId: 't3-survey2',
				text: 'Hvad er vigtigt at tænke på, når børnene skal støttes i at løse konflikter?'
			}
		],
		furtherDiscussion: `<ul>
			<li>Hvor, hvornår og hvordan taler vi sammen i forældregruppen - også når vi er uenige?</li>
			<li>Hvordan kan vi tale med børnene om konflikter uden at optrappe dem?</li> 
			<li>Hvornår og hvordan kræver en konflikt en dialog med skolen?</li></ul>`
	},
	{
		id: 'theme-4',
		title: 'Samarbejde og kommunikation',
		titleShort: 'Samarbejde og kommunikation',
		subtitle: 'Den måde forældrene taler om klassens børn og voksne smitter af på børnene - både på godt og ondt.',
		description: `3. Hvordan håndterer vi konflikter
			<br /><br />Børnene vil opleve konfliktfyldte situationer, og måske søge hjælp hos forældrene til at løse dem. Forældres handlinger kan påvirke konflikten på forskellige måder – både optrappende og nedtrappende. `,
		quote: `“Jeg er slet ikke tryg i forældregruppen og føler mig nok også udenfor, fordi der har været meget opmærksomhed på min søn også fra forældregruppen.”`,
		gameSteps: require('data/game-steps/theme-4-game-steps').gameSteps,
		results: [
			{
				id: 'result-1',
				type: 'sum-all',
				gameStepId: 't4-survey1',
				text: 'Hvad er vigtig at tænke på, når vi er uenige i forældregruppen og skal finde fælles løsninger?'
			},
			{
				id: 'result-2',
				type: 'sum-each',
				gameStepId: 't4-survey2',
				text: 'Hvad er vigtigt at tænke på, når vi skal skabe et trygt og tillidsfuldt samarbejde?'
			}
		],
		furtherDiscussion: `<ul>
			<li>Hvilke kommunikationskanaler bruger vi til hvad? Hvad bruger vi f.eks. ikke Aula til?</li>
			<li>Hvad gør jeg, hvis mit barn er udfordret i fællesskabet?</li>
			<li>Hvordan kan andre i forældregruppen støtte op om barnet eller familien, som er udfordret i fællesskabet?</li></ul>`
	}
];



export {
	themesData
};