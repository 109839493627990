import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './popup-max-answers.scss';

const PopupMaxAnswers = ({deviceInfo, gameStepData, setHasHitAnswerLimit}) => {

	let perPlayer = (gameStepData && gameStepData.maxAnswers ? gameStepData.maxAnswers.perPlayer : 0);

	let popupText = getText('generalTexts', 'maxAnswersLimit1');
	popupText = JSON.parse(JSON.stringify(popupText)).replace('%perPlayer%', perPlayer);

	return (
		<div className={'PopupMaxAnswers ' + deviceInfo.orientation} onClick={() => {setHasHitAnswerLimit(false);}}>
			<div className="PopupMaxAnswers-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="PopupMaxAnswers-text">
					<span>{popupText}</span>
				</div>
				<div className="PopupMaxAnswers-buttons">
					<div className="PopupMaxAnswers-button no">
						<Button 
							text={getText('generalTexts', 'ok')} 
							classes={['pink']} 
							onClick={() => {setHasHitAnswerLimit(false);}} 
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PopupMaxAnswers.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameStepData: PropTypes.object.isRequired,
	setHasHitAnswerLimit: PropTypes.func.isRequired,
};

export default PopupMaxAnswers;
