import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import {QRCodeSVG} from 'qrcode.react';
import {QRCodeCanvas} from 'qrcode.react';
import './qr.scss';

const QR = ({gameCode, url}) => {

	return (
		<div className="QR">
			<div className="QR-info">
				<div className="QR-title">
					<span>{getText('generalTexts', 'gameCode')}:</span>
					<span>{gameCode}</span>
				</div>
			</div>
			<div className="QR-code">
				<QRCodeSVG
					id={'codeSvg'}
					value={url}
					bgColor={'#FFFFFF'}
					fgColor={'#000000'}
				/>
				<QRCodeCanvas
					id={'codePng'}
					value={url}
					bgColor={'#FFFFFF'}
					fgColor={'#000000'}
				/>
			</div>
		</div>
	);
};

QR.propTypes = {
	gameCode: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired
};

export default QR;